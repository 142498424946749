a,
button:not(:disabled),
input:not(:disabled) {
	cursor: pointer;
}

.no-scrollbar {
	scrollbar-arrow-color: transparent;
	scrollbar-color: rgba($color: transparent, $alpha: 0.3) transparent;
	&::-webkit-scrollbar {
		width: 10px;
		position: absolute;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		margin: 16px 0;
	}
	&::-webkit-scrollbar-thumb {
		position: relative;
		background: rgba($color: transparent, $alpha: 0.3);
		border-radius: 3px;
	}
}

.border {
	&-grey200 {
		border: 1px solid $grey200;
	}
}

.circle {
	border-radius: 50%;
	overflow: hidden;
}

.rotate {
	transition: transform 0.3s ease-in-out;
	&-90 {
		transform: rotate(90deg);
	}
	&-270 {
		transform: rotate(270deg);
	}
}

.flex {
	display: flex;
	align-items: center;
	&-col {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.mh-auto {
	margin-left: auto;
	margin-right: auto;
}

.justify {
	&-between {
		justify-content: space-between;
	}
}

.start {
	justify-content: flex-start;
}

.end {
	justify-content: flex-end;
}

.align {
	&-stretch {
		align-items: stretch;
	}
}

.one-line,
.two-line {
	white-space: break-spaces;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -moz-box;
	-moz-box-orient: vertical;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-word;
}

.one-line {
	line-clamp: 1;
	-webkit-line-clamp: 1;
}

.two-line {
	line-clamp: 2;
	-webkit-line-clamp: 2;
}

.hide-scrollbar {
	scrollbar-arrow-color: transparent;
	scrollbar-color: rgba($color: transparent, $alpha: 0.3) transparent;
	&::-webkit-scrollbar {
		width: 0px;
		position: absolute;
		height: 0;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		margin: 0;
		width: 0px;
	}
	&::-webkit-scrollbar-thumb {
		position: relative;
		background: transparent;
		border-radius: 3px;
		width: 0px;
	}
}
