* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	user-select: none;
}

@mixin auth-font-style {
	color: #212121;
	font-family: "Averta CY";
}

.flex-block {
	width: max-content;
	display: inline-flex;
}

.auth {
	background: url("/public/images/authbg.jpeg") no-repeat 50% 50%;
	align-items: center;
	display: flex;
	justify-content: center;
	background-size: cover;
	padding: 80px;
	.auth-form {
		width: 408px;
	}
	.reg-form {
		width: 600px;
	}
	.auth-form,
	#reg-form {
		position: relative;
		display: block;
		padding: 56px 40px 26px;
		background: #ffffff;
		box-shadow: 16px 48px 64px rgba(54, 79, 199, 0.2),
			8px 8px 32px rgba(54, 79, 199, 0.2);
		border-radius: 16px;
		box-sizing: border-box;
		.btn-tinkoff {
			margin: 0 auto;
		}
		h4.title {
			text-align: center;
			margin-bottom: 16px;
			font-size: 24px;
			line-height: 40px;
			font-weight: 600;
			@include auth-font-style;
		}
		.subtitle {
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 12px;
		}
		&__hint {
			margin-bottom: 21px;
			// font-size: 15px;
			line-height: 21px;
		}
		img#logo {
			position: relative;
			display: block;
			margin: 0 auto 48px;
			height: 35px;
			margin-bottom: 20px;
		}
		.flex-block {
			display: flex;
			gap: 16px;
			justify-content: space-between;
			margin-bottom: 24px;
			position: relative;
			@include auth-font-style;
			.form-field {
				.input-field {
					display: block;
					position: relative;
					@include auth-font-style;
					input {
						background: #f4f4f8;
						border: 1px solid #f4f4f8;
						border-radius: 8px;
						box-sizing: border-box;
						display: block;
						font-size: 15px;
						height: 54px;
						outline: none;
						padding: 0 20px;
						position: relative;
						width: 100%;
						@include auth-font-style;
					}
					.input-icon {
						position: absolute;
						display: block;
						width: 20px;
						height: 20px;
						top: 18px;
						right: 16px;
						pointer-events: none;
					}
					.input-field-eye {
						background: url("/public/images/icons/ui/forms/eye_off.svg");
						background-size: contain;
						cursor: pointer;
						pointer-events: all;
						opacity: 0.4;
						&.show-password {
							background: url("/public/images/icons/ui/forms/eye.svg");
							background-size: contain;
							opacity: 1;
						}
					}
				}
				.button.big-button {
					width: 100%;
					border-radius: 28px;
					height: 56px;
					line-height: 56px;
				}
				.form-field-error {
					color: #ed0a34;
					display: none;
					font-size: 13px;
					line-height: 21px;
					margin-top: 8px;
					position: relative;
				}
			}
			.form-field.error {
				.input-field {
					input {
						border: 1px solid #ed0a34;
					}
				}
				.form-field-error {
					display: block;
				}
			}
		}
	}
	&-reset {
		.auth-form {
			padding-bottom: 40px;
		}
	}
	&-reset-success {
		&__svg {
			display: block;
			margin: 53px auto 32px auto;
		}
		.auth-form {
			padding-bottom: 40px;
			h4 {
				&.title {
					margin-bottom: 24px;
				}
			}
		}
		.btn {
			margin-bottom: 0;
		}
	}
}

.cross-line {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: #e2e8f0;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
	}
	span,
	button {
		z-index: 1;
	}
}

.auth-form {
	.btn-purple,
	.btn-tinkoff {
		width: 100%;
	}
	&__or-dividor {
		color: #94a0b1;
		margin: 20px auto;
		display: flex;
		justify-content: center;
		span {
			background-color: #fff;
			padding: 0 12px;
		}
	}
	&__reg-hint {
		font-size: 13px;
		line-height: 21px;
		color: #94a0b1;
		margin-top: 24px;
		text-align: center;
		a {
			color: #212121;
			font-weight: 600;
			transition: color 0.2s ease-in-out;
			&:hover {
				color: #005bff;
			}
		}
	}
}

.auth {
}
