.blogger-profile {
	padding-bottom: 165px !important;
	&-stat {
		display: flex;
		flex-wrap: wrap;
		// display: grid;
		// grid-template-columns: repeat(4, 1fr);
		// grid-template-rows: repeat(2, 1fr);
		// grid-column-gap: 0px;
		// grid-row-gap: 32px;
		&__item {
			width: calc(100% / 4);
			padding-right: 8px;
		}
	}
	&__desc {
		white-space: pre-line;
	}
	&__avatar-block:after {
		content: "";
		position: absolute;
		height: 1px;
		background: $grey200;
		width: 100%;
		line-height: 0;
		padding: 0;
		margin: 0;
		bottom: 160px;
		left: 0;
	}
	.contact-link {
		position: relative;
		cursor: pointer;
		&:before {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 24px;
		}
		&-mail:before {
			content: url("/public/images/icons/ui/actions/blue/mail.svg");
		}
		&-site:before {
			content: url("/public/images/icons/ui/actions/blue/external_link.svg");
		}
		&-tg:before {
			content: url("/public/images/icons/ui/actions/blue/telegram.svg");
		}
		&-inst:before {
			content: url("/public/images/icons/ui/actions/blue/instagram.svg");
		}
		&-tt:before {
			content: url("/public/images/icons/ui/actions/blue/tiktok.svg");
		}
		&-vk:before {
			content: url("/public/images/icons/ui/actions/blue/vkontakte.svg");
		}
	}
}
