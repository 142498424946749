.bloggers-page {
	.list-filter {
		.field {
			margin-bottom: 0 !important;
		}
		.chosen-container {
			padding: 0 0 0 20px !important;
		}
	}
	.list-table-header {
		@include row-between-middle();
		flex-direction: row !important;
		gap: 0 !important;
		padding: 0 !important;
		// justify-content: flex-start !important;
		justify-content: flex-start !important;
		// width: calc(100% - (16.906% + 40px)) !important;
		// padding-right: calc(16.906% + 40px) !important;
	}
	.list-table-header-element {
		text-align: left !important;
		&:nth-child(1) {
			// width: calc(52.579% + 64px + 16px + 16px);
			width: calc(23.739%);
			// background: red;
			// padding-right: 16px;
		}
		&:nth-child(2) {
			// background: green;
			width: 16.1002%;
			// padding-right: 16px;
		}
		&:nth-child(3) {
			width: 7.872%;
			// background-color: yellow;
			// padding-right: 16px;
		}
		&:nth-child(4) {
			width: 9.482%;
			min-width: auto;
			// background: purple;
			// padding-right: 16px;
		}
		&:nth-child(5) {
			width: 10.376%;
			// background: orange;
			// padding-right: 16px;
		}
		&:nth-child(6) {
			width: 11.092%;
			// background: grey;
		}
		&:nth-child(7) {
			width: 15.7895%;
			height: 24px;
			min-width: 164px;
		}
		&:nth-child(8) {
			height: 24px;
			width: 40px;
		}
		&:last-child {
			// width: calc(16.906% + 40px);
		}
	}
	&-table--ad {
		.list-table-header {
			margin-top: 0 !important;
		}
		.list-table-header-element {
			text-align: left !important;
			&:nth-child(1) {
				// width: calc(52.579% + 64px + 16px + 16px);
				width: calc(23.739%);
				// background: red;
				// padding-right: 16px;
			}
			&:nth-child(2) {
				// background: green;
				width: calc(16.1002% + 2.0036%);
				// padding-right: 16px;
			}
			&:nth-child(3) {
				width: calc(7.872% + 2.0036%);
				// background-color: yellow;
				// padding-right: 16px;
			}
			&:nth-child(4) {
				width: calc(9.482% + 2.0036%);
				// background: purple;
				// padding-right: 16px;
			}
			&:nth-child(5) {
				width: calc(10.376% + 2.0036%);
				// background: orange;
				// padding-right: 16px;
			}
			&:nth-child(6) {
				width: calc(11.092% + 2.0036%);
				// background: grey;
			}
			&:nth-child(7) {
				// width: 10.018%;
				width: 40px;
				min-width: auto;
			}
			&:last-child {
				// width: calc(16.906% + 40px);
				width: 40px;
			}
		}
	}
	&-table {
		@include col();
		row-gap: 8px;
	}
	&-table__row {
		cursor: pointer;
		border: 1px solid #e2e8f0;
		border-radius: 16px;
		&:hover {
			border-color: #005bff;
		}
		&-content {
			padding: 20px 0;
			gap: 0 !important;
		}
	}
	&-table__row {
		div {
			max-height: max-content;
		}
		&-avatar {
			width: 23.739%;
			// background: red;
		}
		&-category {
			// background: green;
			width: 16.1002%;
		}
		&-rate {
			width: 7.872%;
			// background-color: yellow;
		}
		&-conversion {
			width: 9.482%;
			// background: purple;
		}
		&-subs {
			width: 10.376%;
			// background: orange;
		}
		&-views {
			width: 11.092%;
			// background: grey;
		}
		&-btn {
			width: 15.7895%;
			min-width: 164px;
			// background: darkcyan;
		}
		&-status {
			width: 10.018%;
		}
	}
	&-table__row--ad {
		.bloggers-page-table__row {
			&-avatar {
				width: 23.739%;
				// background: red;
			}
			&-category {
				// background: green;
				width: calc(16.1002% + 2.0036%);
			}
			&-rate {
				width: 7.872%;
				width: calc(7.872% + 2.0036%);
				// background-color: yellow;
			}
			&-conversion {
				width: 9.482%;
				width: calc(9.482% + 2.0036%);
				// background: purple;
			}
			&-subs {
				width: 10.376%;
				width: calc(10.376% + 2.0036%);
				// background: orange;
			}
			&-views {
				width: 11.092%;
				width: calc(11.092% + 2.0036%);
				// background: grey;
			}
		}
	}
}
