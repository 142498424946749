@import "./var";
@import "./reset";
@import "./mixins";
@import "./base";
@import "./typeset";
@import "./indent";
.w100 {
	width: 100%;
}

.h100 {
	height: 100%;
}

.bg {
	&-white {
		background-color: #fff;
	}
	&-blue {
		background-color: $blue;
	}
	&-grey {
		background-color: #f4f4f8;
	}
}

.mh-auto {
	margin: 0 auto;
}

label {
	cursor: pointer;
}

.row {
	display: flex;
}

.wrap {
}

.col {
	display: flex;
	flex-direction: column;
}

.top {
	align-items: flex-start;
}

.middle {
	align-items: center;
}

.between {
	justify-content: space-between;
}

.bottom {
	align-items: flex-end;
}

.align-self-center {
	align-self: center;
}

.flex1 {
	flex: 1;
}

.opacity {
	&-5 {
		opacity: 0.5;
	}
	&-hover:hover {
		opacity: 1;
		path {
			fill: #fff;
		}
	}
}

.svg {
	&-white path,
	&-white:hover path {
		fill: #ffffff;
	}
}

svg:hover {
	opacity: 1;
}

.link-underline {
	padding-bottom: 2px;
	border-bottom: 1px solid #fff;
}
