.policy-page {
	text-align: justify;
	p {
		margin-top: 0.35cm;
	}
	padding: 32px 40px 32px 40px;
	//72px
	&__header {
		margin-bottom: 32px;
	}
	&__content {
		max-width: 966px;
	}
	&__title {
		font-weight: 600;
		font-size: 40px;
		line-height: 46px;
		margin-bottom: 32px;
	}
	h1 {
		font-size: 14px;
		font-weight: unset;
		line-height: 22px;
		overflow: unset;
		text-overflow: unset;
		white-space: unset;
	}
	b,
	strong {
		font-weight: 900;
	}
	ul li {
		list-style-type: disc;
	}
	ul {
		margin-left: 48px;
	}
	.dash-list {
		margin-left: 40px;
	}
	.dash-list li {
		list-style: none;
	}
	a{
		border-bottom: #00000033 solid 1px;
		&:hover{
			border-bottom: #000 solid 1px;
		}
	}
}
