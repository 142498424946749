.switcher {
    @include row-between-middle;
    width: max-content;
    &-label {
        @include T14();
        padding: 5px 12px;
        border-radius: 80px;
        margin-right: 8px;
        &--rejected {
            color: #fff;
            background: #94A0B1;
        }
        &--disabled {
            color: #94a0b1;
            background: #e2e8f0;
        }
        &--off {
            color: #ed0a34;
            background: rgba(237, 10, 52, 0.15);
        }
        &--on {
            color: #0dc268;
            background: rgba(13, 194, 104, 0.15);
        }
    }
    &-tumbler {
        width: 56px;
        height: 32px;
        border-radius: 80px;
        transition: all 200ms linear;
        cursor: pointer;
        position: relative;
        &:before {
            position: absolute;
            display: block;
            content: "";
            width: 26px;
            height: 26px;
            border-radius: 26px;
            background: #fff;
            left: calc(100% - 29px);
            top: 3px;
            transition: all 200ms ease-in-out;
        }
    }
    &-tip {
        // margin-left: 12px;
    }
    &.disabled {
        .switcher {
            pointer-events: none;
            &-label {
                color: #94a0b1;
                background: #e2e8f0;
            }
            &-tumbler {
                background: #d1d8e1;
                cursor: default;
                pointer-events: none;
                &:before {
                    left: 3px;
                    background: #ffffff;
                    opacity: 0.5;
                }
            }
        }
    }
    &.off {
        .switcher {
            &-label {
                color: #ed0a34;
                background: rgba(237, 10, 52, 0.15);
            }
            &-tumbler {
                background: #ed0a34;
                &:before {
                    left: 3px;
                }
            }
        }
    }
    &.on {
        .switcher {
            &-label {
                color: #0dc268;
                background: rgba(13, 194, 104, 0.15);
            }
            &-tumbler {
                background: #0dc268;
                &:before {
                    left: calc(100% - 29px);
                }
            }
        }
    }
}