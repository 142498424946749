.slideout-modal {
	position: fixed;
	right: -699px;
	top: 0; // width: 580px;
	box-sizing: border-box;
	height: 100vh;
	z-index: 1000;
	background: #fff;
	overflow: hidden;
	transition: all 300ms ease-out;
	z-index: 11;
	&.active {
		// transition: all 300ms ease-out;
		right: 0;
	}
	&-scroller {
		box-sizing: border-box;
		display: block;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0 32px 0 32px;
		position: absolute;
		width: 100%;
	}
	&-bg {
		position: fixed;
		display: block;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		visibility: hidden;
		// transition: all 300ms linear;
		&.active {
			visibility: visible;
			opacity: 1;
			z-index: 10;
		}
	}
}
