.chat-btn {
	position: relative;
	&-counter {
		-webkit-box-pack: center;
		justify-content: center;
		padding: 6px;
		position: absolute;
		right: -10px;
		top: -5px;
		min-width: 19px;
		height: 19px;
		background: rgb(112, 39, 194);
		border-radius: 80px;
		font-family: "Averta CY";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 160%;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		text-align: center;
		color: rgb(255, 255, 255);
	}
}
