.product-list {
	@include col();
	row-gap: 8px;
	.list-table-header-element {
		&:nth-child(1) {
			// width: calc(52.579% + 64px + 16px + 16px);
			width: calc(48.579% + 96px);
		}
		&:nth-child(2) {
			// width: 21.455%;
			width: 18.455%;
		}
		&:nth-child(3) {
			width: 13.596%;
			text-align: left;
			padding-right: 69px;
		}
		&:nth-child(4) {
			width: calc(240px);
			min-width: calc(240px);
			&:after {
				display: none !important;
			}
		}
	}
	@media screen and (max-width: 1024px) {
	}
	.list-table-header,
	&__item {
		padding: 12px 0;
		// padding: 12px 32px 12px 16px;
		// gap: 16px !important;
		gap: 0 !important;
	}
	&__item {
		@include row-between-middle();
		position: relative;
		border: 1px solid $grey200;
		border-radius: 16px;
		cursor: pointer;
		&:hover {
			border-color: #005bff;
		}
		&-img {
			// @include square($size: 64px);
			border-radius: 8px;
			padding: 0 16px;
			overflow: hidden;
			min-width: 96px;
			img {
				display: block;
				object-fit: cover;
				@include square($size: 64px);
				min-width: 64px;
				border-radius: 8px;
				overflow: hidden;
			}
		}
		&-info {
			// width: 52.579%;
			width: 48.579%;
			align-self: stretch;
			justify-content: space-between;
			padding: 2px 0;
			@include col();
			row-gap: 1px;
			// flex: 1;
			padding-right: 16px;
			.prod-attrs {
				@include T12();
				color: $grey400;
			}
			&_title {
				@include T14();
			}
			&_attrs {
				@include T12();
				color: $grey400;
				span {
					width: max-content;
				}
			}
		}
		&-category {
			// width: 21.455%;
			width: 18.455%;
			@include T14-list();
			padding-right: 16px;
		}
		&-price {
			@include T15-bold();
			width: 12.874%;
			width: 13.596%;
			text-align: left;
			padding-right: 64px;
			p {
				// min-width: 62px;
				// width: max-content;
			}
		}
		div {
			box-sizing: border-box;
		}
		&-status {
			display: flex;
			justify-content: center;
			flex-direction: column;
			// padding-right: 32px;
			gap: 10px;
			width: 240px;

			span {
				position: relative;
				display: flex;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-left: 32px;
				font-size: 14px;
				line-height: 20px;
				font-weight: 400;
				padding-right: 16px;
				.tooltip {
					display: none;
				}

				.radio {
					position: absolute;
					left: 0;
					top: calc(50% - 12px);
					box-sizing: border-box;
					border-radius: 50%;
					-webkit-background-clip: padding-box;
					-moz-background-clip: padding;
					background-clip: padding-box;
					@include square($size: 24px);
					content: "";
					border: 8px solid rgba(237, 10, 52, 0.2);
					background-color: $red;
					&:hover {
						.tooltip {
							right: auto;
							display: block;
							transform: translate(calc(-46%), -100%) !important;
							.tooltip-arrow {
								right: calc(50% - 14px);
							}
						}
					}
				}
				&.on {
					.radio {
						border: 8px solid rgba(13, 194, 104, 0.2);
						background-color: #0dc268;
					}
				}
			}
		}
	}
}

.products-page .list-table-header {
	@include row-between-middle();
	flex-direction: row !important;
	gap: 0 !important;
	padding: 0 !important;
	// justify-content: flex-start !important;
	justify-content: space-between !important;
}
