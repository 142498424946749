$yellow: #fedd2c;
$purple: #7027c2;
$red: #ed0a34;
$dark-blue: #0052e4;
$blue: #005bff;
$grey100: #f4f4f8;
$grey200: #e2e8f0;
$grey400: #94a0b1;
:root {
	--grey100: #f4f4f8;
	--grey200: #e2e8f0;
	--grey300: #d1d8e1;
	--grey400: #94a0b1;
	--blue: #005bff;
}
