.reg-form {
	width: 600px;
	&__field-inn {
		button.button {
			// width: 190px;
			width: max-content;
			min-width: 177px;
			height: 56px !important;
			border-radius: 80px !important;
			svg path {
				stroke: #005bff;
			}
		}
		button.button:disabled {
			background: transparent !important;
		}
		&.error-field{
			input{
				border-color: red;
			}
		}
	}
	&__steps {
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		margin-bottom: 24px;
		&-item {
			border-radius: 50%;
			padding: 10px 18px;
			text-align: center;
			border: 2px solid #e2e8f0;
			color: #94a0b1;
			transition: color, background-color 0.4s ease-in-out;
			background-color: #fff;
			z-index: 1;
		}
		&-item {
			&--active {
				background-color: #e2e8f0;
				color: #212121;
			}
			&--passed {
				background-color: #0dc268;
				color: #fff;
				border-color: #0dc268;
			}
		}
	}
	.btn-tinkoff,
	.btn-purple {
		width: unset;
	}
	&__btn-group {
		display: flex;
		justify-content: space-between;
		column-gap: 136px;
		position: relative;
		flex-wrap: wrap;
		.field-error {
			text-align: left;
			margin-top: 0;
			margin-bottom: 16px;
		}
		.btn {
			// width: 100%;
			min-width: 168px;
			&-secondary {
				max-width: 168px;
			}
			&-purple {
				// width: 100%;
				margin-left: auto;
			}
		}
	}
	&__agree-checkbox {
		// margin: 24px 0 38px 0;
		label {
			cursor: default;
		}
		&:hover {
			color: #94a0b1 !important;
		}
		label a:hover {
			color: #005bff;
		}
	}
}
