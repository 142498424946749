.footer {
	&-list {
		display: flex;
		column-gap: 24px;
		row-gap: 16px;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	a {
		font-weight: 400;
		font-size: 13px;
		line-height: 21px;
		color: #94a0b1;
		&:hover {
			color: #005bff;
		}
	}
}
