.home-page-cookies {
	border-radius: 80px;
	box-shadow: 0px 8px 64px rgba(12, 2, 22, 0.7);
	position: fixed;
	bottom: 24px;
	width: calc(100% - 72px * 2);
	left: 50%;
	transform: translateX(-50%);
	z-index: 11;
	padding: 16px 24px 16px 40px;
	justify-content: space-between;
	background-color: #f4f4f8;
	.btn {
		min-width: 104px;
		font-size: 14px;
		line-height: 22px;
	}
}
