@import "../../styles/base/index";
.btn {
	width: max-content;
	font-weight: 600;
	text-align: center;
	// padding: 0 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 80px;
	height: max-content;
	transition: border-color, background-color, color 0.4s ease-in-out;
	&.w100 {
		width: 100%;
	}
	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		path {
			stroke: #fff;
		}
		svg {
			margin: 0 !important;
		}
	}
	&-primary {
		background-color: #005bff;
		color: #fff;
		&:not(:disabled) {
			&:hover {
				background-color: #004ace;
			}
			&:active {
				background-color: #0052e4;
			}
		}
	}
	&-secondary {
		border: 2px solid #d1d8e1;
		.loader path {
			stroke: #005bff;
		}
		&:not(:disabled) {
			&:hover {
				border-color: #005bff;
			}
			&:active {
				border-color: #0052e4;
			}
			&:disabled {
				border-color: #d1d8e1 !important;
				// background-color: #fff !important;
			}
		}
	}
	&-success {
		background-color: #0dc268;
		color: #fff;
		&:hover {
			background-color: #0db461;
		}
	}
	&-tinkoff {
		background-color: #fedd2c;
		color: #212121;
		padding: 0 70px;
		img {
			height: 29px;
			margin-left: 2px;
		}
	}
	&-purple {
		background-color: #7027c2;
		color: #fff;
	}
	&-l {
		padding: 16px 32px;
	}
	&-m {
		padding: 12px 32px;
	}
	&-s {
		padding: 9px 20px;
	}
	&-xss {
		padding: 9px 20px;
	}
}

.icon-btn {
	@include row;
	@include hover-svg;
	&--xxs {
		@include square($size: 24px);
	}
	&--xs {
		@include square($size: 40px);
	}
	&--s {
		@include square($size: 48px);
	}
	&--m {
		@include square($size: 56px);
	}
}
