@import "./base/index";
@import "../ui-kit/forms/forms";
@import "./features/_index.scss";
@import "./pages/_index.scss";
@import "./universal/_index.scss";
@import "./ui-kit/_index.scss";
.radius {
	&-8 {
		border-radius: 8px;
	}
	&-16 {
		border-radius: 16px;
	}
}

.pointer {
	cursor: pointer;
}

.or-dividor {
	color: #94a0b1;
	margin: 20px auto;
	display: flex;
	justify-content: center;
	span {
		background-color: #fff;
		padding: 0 12px;
	}
}

.img-upload {
	// max-width: 360px;
	border: 1px dashed #d1d8e1;
	border-radius: 8px;
	position: relative;
	transition: border-color 0.2s ease-in-out;
	&:hover {
		border-color: #005bff;
	}
	&__del-btn {
		position: absolute;
		top: 16px;
		z-index: 3;
		right: 16px;
		width: 48px;
		height: 48px;
		background-color: $grey100;
		border-radius: 50%;
	}
}

.animte-menu {
	transition: all 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
}

.flex {
	display: flex;
}

.block {
	display: block;
}

.self-stretch {
	align-self: stretch;
}

.hidden-input {
	border-radius: none !important;
	border: none !important;
	&:hover {
		border: none !important;
	}
}

.container {
	background: #f4f4f8;
	padding: 104px 40px 60px 40px;
	min-width: 940px;
}

.list-title {
	position: relative;
	display: flex;
	justify-content: space-between;
	height: 56px;
	margin-bottom: 48px;
}

.scrollbar {
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		width: 6px;
		position: absolute;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		margin: 16px 0;
	}
	&::-webkit-scrollbar-thumb {
		position: relative;
		background: rgba($color: #94a0b1, $alpha: 0.3);
		border-radius: 3px;
	}
}

.justify {
	&-center {
		justify-content: center;
	}
}

.relative {
	position: relative;
}

.main-modal {
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	// width: 100vw;
	width: 100vw;
	z-index: 9999;
	display: flex;
	flex-wrap: wrap;
	.modal-content {
		display: block;
		padding: 50px 0;
		position: relative;
		.add-category-wrapper,
		&-wrapper {
			display: block;
			background: #fff;
			border-radius: 16px;
			box-sizing: border-box;
			padding: 32px;
			position: relative;
			&--center {
				display: flex;
				align-items: center;
				flex-direction: column;
			}
			.modal-field-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 40px;
				margin-bottom: 32px;
				padding-right: 72px;
				position: relative;
			}
			.modal-field-close {
				cursor: pointer;
				display: block;
				position: absolute;
				right: 40px;
				top: 40px;
			}
			.flex-block {
				display: flex;
				gap: 16px;
				justify-content: flex-start;
				// margin-bottom: 24px;
				position: relative;
				.form-field {
					display: block;
					position: relative;
					width: 100%;
					.form-field-label {
						color: #94a0b1;
						font-size: 14px;
						line-height: 22px;
						margin-bottom: 5px;
						margin-top: -3px;
						position: relative;
						display: flex;
						justify-content: space-between;
					}
					.input-field {
						display: block;
						position: relative;
						input,
						.input {
							background: #f4f4f8;
							border: 1px solid #f4f4f8;
							border-radius: 8px;
							box-sizing: border-box;
							color: #212121;
							display: block;
							font-family: Averta CY;
							font-size: 15px;
							height: 54px;
							font-weight: 400;
							outline: none;
							padding: 0 20px;
							position: relative;
							width: 100%;
							&::placeholder {
								color: #94a0b1;
							}
						}
					}
				}
				.flex-block-column {
					display: flex;
					flex-direction: column;
					gap: 12px;
					margin-top: 8px;
					position: relative;
					width: 100%;
					.form-field-label {
						color: #94a0b1;
						display: flex;
						justify-content: space-between;
						font-size: 14px;
						line-height: 22px;
						margin-bottom: 0;
						margin-top: -3px;
						position: relative;
						span {
							color: #212121;
							display: block;
							font-size: 15px;
							font-weight: 600;
							position: relative;
						}
					}
					.radio-button-item {
						cursor: pointer;
						display: block;
						font-size: 15px;
						height: 24px;
						line-height: 24px;
						position: relative;
						span {
							display: block;
							font-weight: 400;
							overflow: hidden;
							padding-left: 38px;
							position: absolute;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.radio-button-item::before {
						background: #f4f4f8;
						border: 1px solid #d1d8e1;
						border-radius: 12px;
						box-sizing: border-box;
						content: "";
						display: block;
						height: 24px;
						left: 0;
						position: absolute;
						top: calc(50% - 12px);
						width: 24px;
					}
					.radio-button-item.selected::before {
						background: #005bff;
						border-color: #005bff;
						border: 1px solid #d1d8e1;
						border-radius: 12px;
						box-sizing: border-box;
						content: "";
						display: block;
						height: 24px;
						left: 0;
						position: absolute;
						top: calc(50% - 12px);
						width: 24px;
					}
					.radio-button-item.selected::after {
						background: #fff;
						border-radius: 4px;
						box-sizing: border-box;
						content: "";
						display: block;
						height: 8px;
						left: 8px;
						position: absolute;
						top: calc(50% - 4px);
						width: 8px;
					}
					.check-button-item {
						cursor: pointer;
						display: block;
						font-size: 15px;
						height: 24px;
						line-height: 24px;
						position: relative;
						span {
							display: block;
							font-weight: 400;
							overflow: hidden;
							padding-left: 38px;
							position: absolute;
							text-overflow: ellipsis;
							white-space: nowrap;
							width: calc(100% - 100px);
						}
					}
					.check-button-item::before {
						background: #f4f4f8;
						border: 1px solid #d1d8e1;
						border-radius: 4px;
						box-sizing: border-box;
						content: "";
						display: block;
						height: 24px;
						left: 0;
						position: absolute;
						top: calc(50% - 12px);
						width: 24px;
					}
					.check-button-item.selected::before {
						background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
						border-color: #005bff;
					}
				}
			}
			.modal-buttons {
				height: auto;
				margin-top: 32px;
				display: flex;
				gap: 8px;
				justify-content: space-between;
				position: relative;
				&.delete {
					gap: 83px;
					.button.action-button {
						height: 48px;
						line-height: 44px;
					}
				}
				// .button.action-button {
				//     border: none;
				//     outline: none;
				//     background: #005bff;
				//     border-radius: 80px;
				//     box-sizing: border-box;
				//     color: #fff;
				//     cursor: pointer;
				//     display: block;
				//     font-size: 15px;
				//     height: 56px;
				//     padding: 0 32px;
				//     line-height: 56px;
				//     position: relative;
				//     font-weight: 600;
				//     text-align: center;
				//     &:hover {
				//         background: #004ace;
				//     }
				// }
				.button.action-button.preloader {
					pointer-events: none;
					font-size: 0;
					color: #fff;
					width: 122px;
					&::after {
						animation: loaderAnimation 1s linear infinite;
						background: url("/public/images/icons/ui/status/white/loader.svg") no-repeat;
						background-size: contain;
						content: "";
						display: block;
						height: 20px;
						left: calc(50% - 10px);
						position: absolute;
						top: calc(50% - 10px);
						width: 20px;
					}
				}
			}
		}
		&-middle {
			margin-bottom: 32px;
		}
		.add-product-wrapper {
			width: 580px;
		}
	}
}

.form-container-progress-blocks {
	.progress-divider {
		position: relative;
		height: 1px;
		background: #e2e8f0;
		margin: 32px 0;
	}
	.form-container-progress-block {
		position: relative;
		display: flex;
		justify-content: space-between;
		height: 24px;
		margin-bottom: 8px;
		font-size: 13px;
		line-height: 24px;
		&:last-child {
			margin-bottom: 0;
		}
		.form-container-progress-block-right {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 70%;
			span {
				position: relative;
				display: block;
				min-width: 44px;
				text-align: right;
				&:last-child {
					min-width: 80px;
				}
			}
			.form-container-progress-block-line {
				position: relative;
				height: 6px;
				border-radius: 6px;
				width: calc(100% - 88px);
				overflow: hidden;
				.form-container-progress-block-linefill {
					position: absolute;
					display: block;
					right: 0;
					top: 0;
					min-width: 6px;
					height: 6px;
					border-radius: 6px;
				}
			}
		}
	}
	&.pink {
		.form-container-progress-block-right {
			span {
				color: #cc5ef5;
			}
			.form-container-progress-block-line {
				.form-container-progress-block-linefill {
					background: #cc5ef5;
				}
			}
		}
	}
	&.red {
		.form-container-progress-block-right {
			span {
				color: #ec479d;
			}
			.form-container-progress-block-line {
				.form-container-progress-block-linefill {
					background: #ec479d;
				}
			}
		}
	}
	&.green {
		.form-container-progress-block-right {
			span {
				color: #0dc268;
			}
			.form-container-progress-block-line {
				.form-container-progress-block-linefill {
					background: #0dc268;
				}
			}
		}
	}
	&.blue {
		.form-container-progress-block-right {
			span {
				color: #005bff;
			}
			.form-container-progress-block-line {
				.form-container-progress-block-linefill {
					background: #005bff;
				}
			}
		}
	}
}

body {
	font-family: "Averta CY";
	padding: 0;
	margin: 0;
	color: #212121;
	font-size: 15px;
	line-height: 24px;
	* {
		//user-select: none;
	}
	h1 {
		font-size: 48px;
		line-height: 55px;
		margin: 0;
		padding: 0;
		font-weight: 600;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	h2 {
		font-size: 32px;
		line-height: 37px;
		margin: 0;
		padding: 0;
		font-weight: 600;
	}
	h3 {
		font-size: 24px;
		line-height: 31px;
		margin: 0;
		padding: 0;
		font-weight: 600;
	}
	h4 {
		font-size: 18px;
		line-height: 24px;
		margin: 0;
		padding: 0;
		font-weight: 600;
	}
	.help-tip {
		position: absolute;
		display: none;
		bottom: calc(100% + 5px);
		padding: 16px;
		filter: drop-shadow(8px 8px 28px rgba(46, 69, 102, 0.2));
		background: #fff;
		border-radius: 8px;
		left: 20px;
		transform: translateX(-50%);
		pointer-events: none;
		span {
			position: relative !important;
			overflow: visible !important;
			text-overflow: unset !important;
			font-size: 13px !important;
			line-height: 20px !important;
			width: auto !important;
		}
		img {
			position: absolute;
			top: 100%;
			left: calc(50% - 32px);
		}
	}
	.svodka-tip {
		position: fixed;
		display: none;
		top: 100px;
		margin-top: -88px;
		padding: 16px;
		filter: drop-shadow(8px 8px 28px rgba(46, 69, 102, 0.2));
		background: #fff;
		border-radius: 8px;
		left: 0;
		transform: translateX(-50%);
		pointer-events: none;
		z-index: 1000;
		text-align: center;
		span {
			position: relative;
			display: block;
			font-size: 15px;
			line-height: 24px;
			width: auto;
		}
		small {
			position: relative;
			display: block;
			font-weight: 400;
			font-size: 13px;
			color: #94a0b1;
			margin-top: 2px;
		}
		img {
			position: absolute;
			top: 100%;
			left: calc(50% - 32px);
		}
	}
	.button {
		position: relative;
		// height: 48px;
		border-radius: 24px;
		cursor: pointer;
		box-sizing: border-box;
		img,
		svg {
			margin-right: 6px;
			vertical-align: middle;
			// top: -2px;
			position: relative;
			display: inline-block;
		}
		&.big-button {
			height: 56px;
			line-height: 56px;
			border-radius: 28px;
			&.with-icon {
				padding-left: 58px;
				img,
				svg {
					position: absolute;
					display: block;
					left: 24px;
					top: 16px;
				}
			}
		}
		&.load-more {
			margin: 0 auto;
			width: 182px;
			height: 66px;
			border: 2px solid #d1d8e1;
			background: none;
			box-sizing: border-box;
			border-radius: 33px;
			line-height: 62px;
			color: #212121;
			font-weight: 600;
			font-size: 15px;
			&:hover {
				color: #005bff;
				border: 2px solid #005bff;
			}
		}
		&.border-button {
			//border: 2px solid #d1d8e1;
			background: none;
			color: #212121;
			// line-height: 44px;
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				border: 2px solid #d1d8e1;
			}
			&:hover:before {
				border: 2px solid #005bff;
			}
			&.preloader {
				&:after {
					background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
					background-size: contain;
				}
			}
		}
		&.purple-button {
			background-color: #7027c2;
		}
		&.small-button {
			font-size: 14px;
			height: 40px;
			line-height: 40px;
			border-radius: 20px;
			text-decoration: none;
			&.preloader {
				&:after {
					width: 16px;
					height: 16px;
					left: calc(50% - 8px);
					top: calc(50% - 8px);
				}
			}
		}
		&.green-button {
			background: #0dc268;
			&:hover {
				background: #0ca458;
			}
		}
		&.disabled {
			background: #d1d8e1;
			color: #94a0b1;
			pointer-events: none;
		}
		&.preloader {
			font-size: 0;
			pointer-events: none;
			&:after {
				position: absolute;
				display: block;
				width: 20px;
				height: 20px;
				content: "";
				left: calc(50% - 10px);
				top: calc(50% - 10px);
				background: url("/public/images/icons/ui/status/white/loader.svg") no-repeat;
				background-size: contain;
				animation: 1s linear infinite loaderAnimation;
			}
		}
	}
	.form-field {
		position: relative;
		display: block;
		width: 100%;
		&--fourth {
			width: 25%;
		}
		&--third {
			width: 38.065%;
		}
		&--half {
			width: 50%;
		}
		&__bottom-link {
			color: #005bff;
			font-size: 13px;
			line-height: 21px;
			font-weight: 600;
		}
		.form-field-error {
			position: relative;
			display: none;
			margin-top: 8px;
			font-size: 13px;
			line-height: 21px;
			color: #ed0a34;
		}
		&.error {
			.input-field {
				input {
					border: 1px solid #ed0a34;
				}
			}
			.form-field-error {
				display: block;
			}
		}
		.input-field {
			position: relative;
			display: block;
			&.show-password {
				.input-field-eye {
					background: url("/public/images/icons/ui/forms/eye.svg");
					background-size: contain;
					opacity: 1;
				}
			}
			.input-icon {
				position: absolute;
				display: block;
				width: 20px;
				height: 20px;
				top: 18px;
				right: 16px;
				pointer-events: none;
			}
			.input-field-eye {
				background: url("/public/images/icons/ui/forms/eye_off.svg");
				background-size: contain;
				cursor: pointer;
				pointer-events: all;
				opacity: 0.4;
			}
			.input-search {
				background: url("/public/images/icons/ui/navigation/search.svg");
				background-size: contain;
			}
			&.left-icon {
				.input-icon {
					right: auto;
					left: 16px;
				}
				input {
					padding-left: 50px;
				}
			}
			//&.date-range-field{
			//  position: relative;
			//  display: flex;
			//  justify-content: flex-start;
			//  background: #F4F4F8;
			//  border-radius: 8px;
			//  font-size: 15px;
			//  height: 54px;
			//  input{
			//    &#statistics-range1{
			//      position: relative;
			//      display: inline-block;
			//      vertical-align: middle;
			//      width: 70px;
			//      padding: 0;
			//      padding-left: 20px;
			//      &:hover{
			//        border: 0;
			//      }
			//      &:focus{
			//        border: 0;
			//      }
			//    }
			//    &#statistics-range2{
			//      position: relative;
			//      display: inline-block;
			//      vertical-align: middle;
			//      padding: 0;
			//      &:hover{
			//        border: 0;
			//      }
			//      &:focus{
			//        border: 0;
			//      }
			//    }
			//  }
			//}
			#statistics-range-calendar {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: url("/public/images/icons/ui/forms/calendar.svg") no-repeat calc(100% - 16px) 15px;
			}
			#statistics-range-calendar-clear {
				cursor: pointer;
				display: none;
				position: absolute;
				right: 45px;
				top: 16px;
				line-height: 0;
				&:hover {
					svg {
						path {
							fill: #005bff;
						}
					}
				}
			}
			input,
			.input {
				position: relative;
				display: block;
				outline: none;
				background: #f4f4f8;
				border-radius: 8px;
				font-size: 15px;
				height: 54px;
				border: 1px solid #f4f4f8;
				font-family: "Averta CY";
				color: #212121;
				padding: 0 20px;
				box-sizing: border-box;
				width: 100%;
				&#statistics-range-visible {
					pointer-events: none;
				}
				&:hover {
					border: 1px solid #d1d8e1;
				}
				&:focus {
					border: 1px solid #005bff;
					&::placeholder {
						color: rgba(0, 0, 0, 0);
					}
				}
				&:disabled,
				&-disabled {
					background: #d1d8e1;
					border: 1px solid #d1d8e1;
					color: #94a0b1;
				}
				&::placeholder {
					color: #94a0b1;
				}
			}
			textarea {
				position: relative;
				display: block;
				outline: none;
				background: #f4f4f8;
				border-radius: 8px;
				font-size: 15px;
				line-height: 24px;
				min-height: 54px;
				height: auto;
				background: #f4f4f8;
				border: 1px solid #f4f4f8;
				font-family: "Averta CY";
				resize: none;
				color: #212121;
				padding: 15px 20px;
				box-sizing: border-box;
				width: 100%;
				&:hover {
					border: 1px solid #d1d8e1;
				}
				&:focus {
					border: 1px solid #005bff;
					&::placeholder {
						color: rgba(0, 0, 0, 0);
					}
				}
				&:disabled {
					background: #d1d8e1;
					border: 1px solid #d1d8e1;
					color: #94a0b1;
				}
				&::placeholder {
					color: #94a0b1;
				}
			}
			&.disabled {
				pointer-events: none;
				input {
					color: #94a0b1;
					background: #d1d8e1;
				}
				.select-block {
					color: #94a0b1;
					background: #d1d8e1;
				}
			}
			.select-block {
				position: relative;
				display: block;
				outline: none;
				background: #f4f4f8;
				border-radius: 8px;
				font-size: 15px;
				height: 54px;
				background: #f4f4f8;
				border: 1px solid #f4f4f8;
				font-family: "Averta CY";
				cursor: pointer;
				&.in-statistics {
					background: none;
					border: 1px solid #fff;
					height: 24px;
					&:hover {
						border: 1px solid #fff;
						.select-block-button {
							span {
								color: #005bff;
							}
							svg {
								path {
									fill: #005bff;
								}
							}
						}
					}
					&.active {
						.select-block-button {
							svg {
								transform: rotate(180deg);
							}
						}
					}
					.select-block-button {
						line-height: 24px;
						padding: 0;
						svg {
							position: absolute;
							display: block;
							content: "";
							width: 24px;
							height: 24px;
							right: 0;
							top: 0;
							pointer-events: none;
						}
						&:after {
							display: none;
						}
						span {
							position: absolute;
							display: block;
							width: calc(100% - 28px);
							white-space: nowrap;
							overflow: hidden;
							font-weight: 600;
							text-overflow: ellipsis;
						}
					}
				}
				&:hover {
					border: 1px solid #d1d8e1;
				}
				&.active {
					.select-block-button {
						&:after {
							transform: rotate(180deg);
						}
					}
					.select-block-list {
						display: block;
					}
				}
				.select-block-control {
					display: none;
				}
				&.multiselect {
					.select-block-list {
						.select-block-list-scroll {
							max-height: 246px;
							.select-block-item {
								span {
									padding-left: 38px;
									width: calc(100% - 100px);
								}
								&:before {
									position: absolute;
									display: block;
									left: 20px;
									top: calc(50% - 12px);
									content: "";
									width: 24px;
									height: 24px;
									box-sizing: border-box;
									background: #f4f4f8;
									border: 1px solid #d1d8e1;
									border-radius: 4px;
								}
								&.selected {
									&:before {
										background: #005bff url("/public/images/icons/ui/forms/white/check.svg")
											no-repeat 50% 50%;
										border-color: #005bff;
									}
									&:hover {
										span {
											color: #212121;
										}
									}
								}
								&:hover {
									span {
										color: #005bff;
									}
								}
							}
						}
						.select-block-list-bar {
							height: calc(100% - 76px);
						}
						.select-block-control {
							position: relative;
							display: flex;
							justify-content: space-between;
							height: 44px;
							border-top: 1px solid #e2e8f0;
							.select-block-cancel {
								position: relative;
								line-height: 44px;
								padding: 0 20px;
								font-size: 15px;
								color: #94a0b1;
								cursor: pointer;
							}
							.select-block-select {
								position: relative;
								line-height: 44px;
								padding: 0 20px;
								font-size: 15px;
								color: #005bff;
								cursor: pointer;
							}
						}
					}
				}
				.select-block-button {
					font-size: 15px;
					line-height: 54px;
					padding: 0 20px;
					&.not-selected {
						color: #94a0b1;
					}
					&:after {
						position: absolute;
						display: block;
						content: "";
						width: 24px;
						height: 24px;
						top: 16px;
						right: 16px;
						pointer-events: none;
						background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
					}
					span {
						position: absolute;
						display: block;
						width: calc(100% - 60px);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.select-block-list {
					position: absolute;
					display: none;
					cursor: default;
					width: 100%;
					top: calc(100% + 8px);
					z-index: 100;
					background: #ffffff;
					box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
					border-radius: 8px;
					overflow-x: hidden;
					.select-block-list-bar {
						position: absolute;
						display: block;
						width: 4px;
						pointer-events: none;
						top: 16px;
						right: 8px;
						height: calc(100% - 32px);
						border-radius: 2px;
						overflow: hidden;
						.select-block-list-thumb {
							position: absolute;
							height: 30%;
							width: 100%;
							left: 0;
							top: 0;
							background: #94a0b1;
							opacity: 0.3;
							border-radius: 2px;
						}
					}
					.select-block-list-scroll {
						position: relative;
						display: block;
						width: calc(100% + 20px);
						overflow-y: scroll;
						max-height: 280px;
						.select-block-item {
							position: relative;
							display: block;
							height: 40px;
							font-size: 15px;
							line-height: 40px;
							padding: 0 20px;
							cursor: pointer;
							&:first-child {
								margin-top: 16px;
							}
							&:last-child {
								margin-bottom: 16px;
							}
							&:hover {
								background: #f4f4f8;
								span {
									color: #005bff;
								}
							}
							span {
								position: absolute;
								display: block;
								width: calc(100% - 60px);
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}
		}
	}
	.flex-block,
	form {
		position: relative;
		display: flex;
		justify-content: flex-start;
		gap: 16px;
		&.flex-start {
			justify-content: flex-start;
		}
		&.no-gap {
			gap: 0;
		}
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
		&.form-container-block-preview {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 360px;
			background-image: url("data:image/svg'+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-width='1' stroke-dasharray='8 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg'%3e");
			border-radius: 8px;
			cursor: pointer;
			&:hover {
				background-image: url("data:image/svg'+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-width='1' stroke-dasharray='8 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg'%3e");
			}
			.form-container-block-preview-image {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				pointer-events: none;
			}
			input {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				opacity: 0;
				cursor: pointer;
			}
			.form-container-block-preview-data {
				position: relative;
				display: block;
				font-size: 13px;
				line-height: 18px;
				text-align: center;
				color: #94a0b1;
				span {
					position: relative;
					display: block;
					font-size: 14px;
					line-height: 20px;
					margin-bottom: 40px;
					margin-top: 17px;
					color: #212121;
				}
			}
		}
		&.form-container-photo-gallery {
			position: relative;
			display: block;
			span {
				position: relative;
				display: block;
				font-size: 14px;
				line-height: 23px;
				color: #94a0b1;
				margin-bottom: 16px;
			}
			.form-container-photo-gallery-items {
				position: relative;
				display: flex;
				justify-content: flex-start;
				width: 100%;
				gap: 8px;
				.form-container-photo-gallery-item {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 152px;
					height: 152px;
					background-image: url("data:image/svg'+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-width='1' stroke-dasharray='8 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg'%3e");
					border-radius: 8px;
					.remove-preview-btn {
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						width: 32px;
						height: 32px;
						border-radius: 16px;
						right: 8px;
						top: 8px;
						background: #fff;
						&:hover {
							svg {
								path {
									fill: #005bff;
								}
							}
						}
					}
					&.empty {
						cursor: pointer;
						input {
							position: absolute;
							display: block;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							opacity: 0;
							cursor: pointer;
						}
						&:hover {
							background-image: url("data:image/svg'+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-width='1' stroke-dasharray='8 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg'%3e");
						}
						&:after {
							position: absolute;
							display: block;
							content: "";
							width: 24px;
							height: 24px;
							left: calc(50% - 12px);
							top: calc(50% - 12px);
							background: url("/public/images/icons/ui/actions/gray/plus_big.svg");
							background-size: 24px 24px;
							pointer-events: none;
						}
						&.loading {
							&:after {
								background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
								background-size: contain;
								animation: 1s linear infinite loaderAnimation;
							}
						}
					}
				}
			}
		}
		.form-field-label {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: -3px;
			font-size: 14px;
			line-height: 22px;
			color: #94a0b1;
			margin-bottom: 5px;
			&__link,
			a {
				font-size: 13px;
				line-height: 21px;
			}
			span {
				position: relative;
				display: block;
				font-weight: 600;
				font-size: 15px;
				color: #212121;
			}
		}
		.flex-block-column {
			position: relative;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-top: 8px;
			gap: 12px;
			.form-field-label {
				margin-bottom: 0px;
			}
		}
		.radio-button-item {
			cursor: pointer;
			display: block;
			font-size: 15px;
			height: 24px;
			line-height: 24px;
			position: relative;
			span {
				padding-left: 38px;
				width: calc(100% - 100px);
				display: block;
				overflow: hidden;
				position: absolute;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: 400;
			}
			&:hover {
				span {
					color: #005bff;
				}
			}
			&:before {
				background: #f4f4f8;
				border: 1px solid #d1d8e1;
				border-radius: 12px;
				box-sizing: border-box;
				content: "";
				display: block;
				height: 24px;
				left: 0;
				position: absolute;
				top: calc(50% - 12px);
				width: 24px;
			}
			&.selected {
				&:hover {
					span {
						color: #212121;
					}
				}
				&:before {
					background: #005bff;
					border-color: #005bff;
				}
				&:after {
					position: absolute;
					display: block;
					content: "";
					background: #ffffff;
					border-radius: 4px;
					box-sizing: border-box;
					height: 8px;
					left: 8px;
					top: calc(50% - 4px);
					width: 8px;
				}
			}
		}
		.flex-block-column-label {
			span {
				display: block;
				overflow: hidden;
				position: relative;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: 400;
				line-height: 24px;
				font-size: 15px;
			}
		}
		.inline-field {
			width: auto;
			margin-right: 16px;
			.check-button-item {
				span {
					position: relative;
					width: auto;
				}
			}
		}
		.check-button-item {
			cursor: pointer;
			display: block;
			font-size: 15px;
			height: 24px;
			line-height: 24px;
			position: relative;
			&.disabled {
				pointer-events: none;
				span {
					color: #94a0b1;
				}
				&:before {
					border: 1px solid #d1d8e1;
					background: #d1d8e1;
				}
				&.selected {
					&:before {
						background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
						border-color: #005bff;
					}
				}
			}
			span {
				padding-left: 38px;
				width: calc(100% - 100px);
				display: block;
				overflow: hidden;
				position: absolute;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: 400;
			}
			&:hover {
				span {
					color: #005bff;
				}
			}
			&:before {
				background: #f4f4f8;
				border: 1px solid #d1d8e1;
				border-radius: 4px;
				box-sizing: border-box;
				content: "";
				display: block;
				height: 24px;
				left: 0;
				position: absolute;
				top: calc(50% - 12px);
				width: 24px;
			}
			&.selected {
				&:hover {
					span {
						color: #212121;
					}
				}
				&:before {
					background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
					border-color: #005bff;
				}
			}
		}
	}
	#main-menu {
		position: fixed;
		width: 240px;
		border-right: 1px solid #f4f4f8;
		left: 0;
		top: 0;
		height: 100vh;
		padding: 40px;
		padding-top: 32px;
		box-sizing: border-box;
		overflow: hidden;
		background: #fff;
		#inner-logo {
			position: relative;
			margin-bottom: 48px;
			#inner-logo-colors {
				position: absolute;
				display: flex;
				top: 0;
				left: 90px;
				width: 67px;
				justify-content: center;
				.inner-logo-color {
					position: relative;
					display: block;
					width: 15px;
					height: 30px;
					left: 0;
					top: 0;
					overflow: hidden;
					img {
						position: absolute;
						display: block;
						left: 0;
						top: 0;
					}
					&:nth-child(2) {
						width: 37px;
						img {
							left: -15px;
						}
					}
					&:nth-child(3) {
						img {
							left: auto;
							right: 0;
						}
					}
				}
			}
			img#logo-inner-image {
				position: relative;
				display: block;
				left: 0;
			}
		}
		z-index: 10;
		span {
			position: relative;
			display: block;
			opacity: 1;
			visibility: visible;
		}
		&.closed {
			width: 88px;
			padding-left: 32px;
			padding-right: 32px;
			span {
				opacity: 0;
				visibility: hidden;
			}
			a#logout {
				left: 32px;
				span {
					opacity: 0;
					visibility: hidden;
				}
			}
			#inner-logo {
				img#logo-inner-image {
					opacity: 0;
					left: -20px;
					visibility: hidden;
				}
				#inner-logo-colors {
					width: 30px;
					left: -3px;
					.inner-logo-color {
						&:nth-child(2) {
							width: 0;
						}
					}
				}
			}
		}
		a#logout {
			position: absolute;
			display: block;
			left: 40px;
			bottom: 40px;
			padding-left: 40px;
			font-size: 15px;
			color: #212121;
			line-height: 24px;
			text-decoration: none;
			//background: url('/public/images/icons/ui/actions/off.svg') no-repeat 0 0;
			svg {
				position: absolute;
				left: 0;
				top: 0;
			}
			&:hover {
				color: #005bff;
				path {
					fill: #005bff;
				}
				//background: url('/public/images/icons/ui/actions/blue/off.svg') no-repeat 0 0;
			}
		}
		&.transition {
			transition: all 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
			#inner-logo {
				#inner-logo-colors {
					transition: all 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
					.inner-logo-color {
						transition: all 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
					}
				}
				img#logo-inner-image {
					transition: all 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
				}
			}
			span {
				transition: 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
				transition-property: opacity, visibility;
			}
			a#logout {
				transition: 300ms ease-in-out;
				transition-property: opacity, left;
			}
		}
		#inner-menu {
			a {
				position: relative;
				display: block;
				padding-left: 40px;
				font-size: 14px;
				line-height: 24px;
				margin-bottom: 18px;
				color: #212121;
				text-decoration: none;
				svg {
					position: absolute;
					left: 0;
					top: 0;
				}
				&:hover {
					color: #005bff;
					path {
						fill: #005bff;
					}
				}
				&.active {
					color: #005bff;
					path {
						fill: #005bff;
					}
				}
			}
		}
	}
	section {
		position: relative;
		display: block;
		min-height: 100vh;
		overflow-x: hidden;
		#product-content {
			position: relative;
			display: flex;
			justify-content: space-between;
			gap: 169px;
			min-width: 940px;
			.product-content-right {
				position: relative;
				display: block;
				min-width: 350px;
				width: 350px;
				b {
					position: relative;
					display: block;
					font-weight: 600;
					font-size: 40px;
					line-height: 46px;
					margin-bottom: 8px;
				}
				#product-price {
					position: relative;
					display: block;
					margin-top: 29px;
					margin-bottom: 16px;
					font-weight: 600;
					font-size: 32px;
					line-height: 37px;
				}
			}
			.product-content-left {
				width: 100%;
				.product-description {
					position: relative;
					display: block;
					margin-bottom: 64px;
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					.product-description-title {
						position: relative;
						display: block;
						font-weight: 600;
						font-size: 24px;
						line-height: 31px;
						margin-bottom: 24px;
					}
				}
				.product-char {
					position: relative;
					display: block;
					margin-bottom: 64px;
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					.product-char-chars {
						position: relative;
						display: flex;
						justify-content: space-between;
						.product-char-chars-list {
							position: relative;
							display: block;
							width: calc(50% - 45px);
							.product-char-chars-list-item {
								position: relative;
								display: flex;
								justify-content: space-between;
								gap: 10px;
								margin-bottom: 12px;
								font-weight: 400;
								font-size: 15px;
								line-height: 24px;
								color: #94a0b1;
								span {
									position: relative;
									display: block;
									right: 0;
									top: 0;
									&:nth-child(2) {
										color: #212121;
										text-align: right;
									}
								}
							}
						}
					}
					.product-char-title {
						position: relative;
						display: block;
						font-weight: 600;
						font-size: 24px;
						line-height: 31px;
						margin-bottom: 24px;
					}
				}
				.product-gallery {
					position: relative;
					display: flex;
					justify-content: space-between;
					gap: 47px;
					margin-bottom: 97px;
					.product-gallery-image {
						position: relative;
						display: block;
						width: 100%;
						img {
							width: 100%;
							height: 528px;
							object-fit: contain;
						}
					}
					.product-gallery-previews {
						position: relative;
						margin-top: 24px;
						min-width: 64px;
						.product-gallery-preview {
							position: relative;
							display: block;
							width: 64px;
							height: 64px;
							border: 1px solid #e2e8f0;
							border-radius: 8px;
							margin-bottom: 8px;
							box-sizing: border-box;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							&.active {
								border: 1px solid #005bff;
							}
						}
					}
				}
			}
		}
		&#container {
			position: relative;
			background: #f4f4f8;
			padding: 0 40px;
			padding-bottom: 60px;
			// padding-top: 104px;
			padding-top: 32px;
			min-width: 940px;
		}
		&#container,
		&.container {
			&.transition {
				transition: margin 300ms cubic-bezier(0.63, 0.21, 0.37, 0.95);
			}
			&.menu-closed {
				margin-left: 88px;
			}
			&.products-page {
				background: #fff;
			}
			&.product-page {
				#inner-logo {
					margin-right: 32px;
					font-size: 0;
				}
				.form-field {
					height: 48px;
					margin-left: 16px;
					margin-right: 35px;
					.input-field input {
						height: 48px;
					}
					.input-icon {
						top: 14px;
					}
				}
			}
			#product-tumbler {
				position: relative;
				display: block;
				width: 56px;
				height: 32px;
				background: #0dc268;
				border-radius: 80px;
				transition: all 200ms linear;
				cursor: pointer;
				&:after {
					position: absolute;
					display: block;
					content: "";
					width: 26px;
					height: 26px;
					border-radius: 26px;
					background: #fff;
					left: calc(100% - 29px);
					top: 3px;
					transition: all 200ms ease-in-out;
				}
				&.disabled {
					background: #ed0a34;
					&:after {
						left: 3px;
					}
					#product-tumbler-text {
						width: 137px;
						background: rgba(237, 10, 52, 0.15);
						span {
							opacity: 0;
							&:nth-child(2) {
								opacity: 1;
							}
						}
					}
				}
				#product-tumbler-text {
					position: absolute;
					display: block;
					left: 64px;
					top: 0;
					width: 80px;
					height: 32px;
					background: rgba(13, 194, 104, 0.15);
					border-radius: 80px;
					text-align: center;
					overflow: hidden;
					pointer-events: none;
					transition: all 200ms ease-in-out;
					span {
						position: absolute;
						display: block;
						width: 80px;
						left: calc(50% - 40px);
						color: #0dc268;
						font-weight: 400;
						font-size: 14px;
						line-height: 32px;
						opacity: 1;
						transition: all 200ms linear;
						&:nth-child(2) {
							opacity: 0;
							width: 137px;
							left: calc(50% - 68px);
							color: #ed0a34;
						}
					}
				}
			}
			.form-page-back {
				font-size: 14px;
				line-height: 24px;
				color: #94a0b1;
				text-decoration: none;
				font-weight: 400;
				svg {
					position: relative;
					display: inline-block;
					vertical-align: top;
					margin-right: 8px;
					path {
						transition: fill 0.4s ease-in-out;
					}
				}
				&:hover {
					color: #005bff;
					svg {
						path {
							fill: #005bff;
						}
					}
				}
			}
			#products-filter-buttons {
				position: relative;
				display: flex;
				justify-content: space-between;
				margin-bottom: 26px;
				.products-all-filter-button {
					position: relative;
					display: flex;
					align-items: center;
					height: 24px;
					justify-content: space-between;
					gap: 12px;
					font-weight: 600;
					font-size: 24px;
					line-height: 32px;
					cursor: pointer;
					&:hover {
						color: #005bff;
						svg {
							path {
								fill: #005bff;
							}
						}
					}
				}
				.products-column-button {
					position: relative;
					display: flex;
					align-items: center;
					height: 24px;
					justify-content: space-between;
					gap: 2px;
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					cursor: pointer;
					&:hover {
						color: #005bff;
						svg {
							path {
								fill: #005bff;
							}
						}
					}
				}
			}
			.container-header {
				position: relative;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding-top: 24px;
				height: 40px;
				margin-bottom: 44px;
				#burger {
					position: absolute;
					display: block;
					left: -8px;
					top: 24px;
					width: 40px;
					height: 40px;
					cursor: pointer;
					background: url("/public/images/icons/ui/actions/menu-list.svg") no-repeat 50% 50%;
				}
				#container-header-right {
					position: relative;
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 24px;
					right: 0;
					top: 0;
					.header-money {
						position: relative;
						display: block;
						width: 72px;
						height: 32px;
						background: #7027c2;
						border-radius: 80px;
						font-weight: 400;
						font-size: 14px;
						line-height: 32px;
						color: #ffffff;
						text-align: center;
					}
				}
			}
			.list-title {
				.list-title-text {
					max-width: calc(100% - 200px);
					h1 {
						display: flex;
					}
					&_counter {
						font-size: 14px;
						line-height: 22px;
						font-weight: normal;
						color: #94a0b1;
						display: block;
						margin-left: 6px;
						// vertical-align: top;
					}
					sup {
						position: relative;
						display: inline-block;
						vertical-align: top;
						font-size: 14px;
						line-height: 22px;
						font-weight: normal;
						// margin-left: 8px;
						color: #94a0b1;
					}
				}
			}
			.form-page-title {
				position: relative;
				display: block;
				font-weight: 600;
				font-size: 32px;
				line-height: 40px;
				margin-top: 24px;
				margin-bottom: 24px;
				max-width: 700px;
			}
			.list-filter {
				margin-bottom: 48px;
				.form-field {
					margin-bottom: 0;
				}
			}
			.list-table-header {
				position: relative;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 16px;
				margin-top: 48px;
				padding-right: 20px;
				.list-table-header-element:nth-child(1) {
					padding-left: 0;
				}
				.list-table-header-element:nth-child(2) {
					padding-left: 0;
				}
				.list-table-header-element:last-child {
					padding-left: 0;
				}
				.list-table-header-element {
					white-space: nowrap;
					font-size: 14px;
					line-height: 24px;
					color: #94a0b1;
					box-sizing: border-box;
					cursor: pointer;
					&.hidden {
						display: none;
					}
					// &:after {
					// 	position: relative;
					// 	display: inline-block;
					// 	width: 24px;
					// 	height: 24px;
					// 	content: "";
					// 	background: url("/public/images/icons/ui/actions/gray/sort_filled.svg")
					// 		no-repeat;
					// 	vertical-align: top;
					// }
					&:hover {
						color: #005bff;
						&:after {
							background: url("/public/images/icons/ui/actions/blue/sort_filled.svg") no-repeat;
						}
					}
				}
				&.user-list {
					margin-top: 48px;
					.list-table-header-element:nth-child(1) {
						width: 31.5%;
						padding-left: 0;
					}
					.list-table-header-element:nth-child(2) {
						width: 25.6%;
					}
					.list-table-header-element:nth-child(3) {
						width: 25.6%;
					}
					.list-table-header-element:nth-child(4) {
						width: 193px;
						margin-right: 16px;
					}
				}
			}
			.list-table-header-element,
			.list-table-item-element {
				&:not(:last-child) {
					// padding-right: 14px;
				}
			}
			.list-table {
				margin-bottom: 72px;
				.list-table-item {
					position: relative;
					display: flex;
					justify-content: flex-start;
					justify-content: space-between;
					// &:last-child {
					//     border: none;
					// }
					&.click-statistics {
						pointer-events: none;
					}
					&:hover {
						background: #f4f4f8;
					}
					.list-table-item-element {
						position: relative;
						display: block;
						box-sizing: border-box;
						line-height: 64px;
						&.hidden {
							display: none !important;
						}
						span {
							position: absolute;
							display: block;
							width: calc(100% - 30px);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							pointer-events: none;
							a {
								color: #212121;
								text-decoration: none;
								&:hover {
									color: #005bff;
								}
							}
						}
						&:last-child {
							// flex: 1;
							display: flex;
							justify-content: flex-end;
							align-items: center;
							justify-content: space-between;
						}
						.list-table-item-status {
							position: relative;
							display: block;
							width: 40px;
							height: 40px;
							margin-left: -16px;
							&:hover {
								.help-tip {
									display: block;
								}
							}
							&:after {
								position: absolute;
								display: block;
								content: "";
								left: calc(50% - 4px);
								top: calc(50% - 4px);
								width: 8px;
								height: 8px;
								background: #0dc268;
								border-radius: 8px;
							}
							&.disable {
								&:after {
									background: #ed0a34;
								}
							}
						}
						.list-table-item-edit {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 40px;
							height: 40px;
							background-size: 24px 24px;
							cursor: pointer;
							&:hover {
								path {
									fill: #005bff;
								}
							}
						}
						.list-table-item-constructor {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 40px;
							height: 40px;
							background-size: 24px 24px;
							cursor: pointer;
							&:hover {
								path {
									fill: #005bff;
								}
							}
						}
						.list-table-item-menu {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 40px;
							height: 40px;
							background-size: 24px 24px;
							cursor: pointer;
							&:hover {
								path {
									fill: #005bff;
								}
							}
							&.active {
								.list-table-item-submenu {
									display: block;
								}
								path {
									fill: #005bff;
								}
							}
							.list-table-item-submenu {
								position: absolute;
								display: none;
								right: 0;
								top: 40px;
								padding: 12px 0;
								background: #ffffff;
								box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
								border-radius: 8px;
								z-index: 10;
								.list-table-item-submenu-item {
									position: relative;
									display: block;
									padding: 4px 24px 4px 20px;
									font-weight: 400;
									font-size: 14px;
									line-height: 30px;
									white-space: nowrap;
									cursor: pointer;
									&:hover {
										background: #f4f4f8;
										color: #005bff;
									}
								}
							}
						}
					}
				}
				.list-table-item {
					align-items: center;
					margin-bottom: 8px;
					border: 1px solid #e2e8f0;
					border-radius: 16px;
					padding: 0 16px;
					column-gap: 8px;
					&:hover {
						background: none;
						border-color: #005bff;
					}
					.list-table-item-element:nth-child(1) {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						column-gap: 16px;
						// padding-right: 14px;
						line-height: 16px;
						img {
							width: 64px;
							height: 64px;
							object-fit: cover;
							border-radius: 8px;
						}
						div {
							position: relative;
							display: block;
							width: calc(100% - 48px);
							span {
								position: relative;
								display: block;
								//width: calc(100% - 48px);
								margin-bottom: 2px;
								cursor: pointer;
								line-height: 22px;
								&:hover {
									color: #005bff;
								}
							}
							small {
								font-weight: 400;
								font-size: 12px;
								line-height: 16px;
								color: #94a0b1;
								span {
									line-height: 15px;
								}
							}
						}
					}
					.list-table-item-element:nth-child(2) {
						padding-left: 0;
					}
					.list-table-item-element:last-child(3) {
						font-size: 15px;
						line-height: 24px;
						padding: 0 20px 0 0;
						font-weight: 600;
					}
					//.list-table-item-element:nth-child(4){
					//  position: relative;
					//  display: flex;
					//  align-items: center;
					//  justify-content: flex-end;
					//  margin-right: 24px;
					//  min-width: 203px;
					//  padding-left: 0;
					//}
				}
			}
			.load-more-items {
				margin-bottom: 32px;
			}
			.list-pages {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				.list-prev-page {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 56px;
					height: 56px;
					background: #f4f4f8;
					border-radius: 80px;
					margin-right: 24px;
					cursor: pointer;
					&.disabled {
						background: none;
						opacity: 0.3;
						pointer-events: none;
					}
					&:hover {
						path {
							fill: #005bff;
						}
					}
				}
				.list-next-page {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #f4f4f8;
					width: 56px;
					height: 56px;
					border-radius: 80px;
					margin-left: 24px;
					cursor: pointer;
					&.disabled {
						background: none;
						opacity: 0.3;
						pointer-events: none;
					}
					&:hover {
						path {
							fill: #005bff;
						}
					}
				}
				.list-page-divider {
					width: 40px;
					height: 40px;
					font-size: 14px;
					line-height: 40px;
					text-align: center;
				}
				.list-pages-list {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;
					.list-page-item {
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 40px;
						height: 40px;
						border-radius: 4px;
						color: #212121;
						cursor: pointer;
						&:hover {
							color: #005bff;
							background: #f4f4f8;
						}
						&.active {
							pointer-events: none;
							color: #fff;
							background: #005bff;
						}
					}
				}
			}
			.form-container-blocks {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				gap: 29px;
				.form-divider {
					position: relative;
					height: 1px;
					background: #e2e8f0;
					margin: 32px 0;
				}
				.form-container-right {
					position: relative;
					display: block;
					box-sizing: border-box;
					width: 100%;
					padding: 32px;
					background: #ffffff;
					border-radius: 8px;
					.form-container-right-b {
						position: relative;
						display: block;
						font-size: 15px;
						line-height: 24px;
						font-weight: 600;
						margin-bottom: 11px;
						span {
							position: absolute;
							display: block;
							right: 0;
							top: 0;
							font-weight: 400;
						}
					}
					.form-container-right-color {
						position: relative;
						display: block;
						font-size: 14px;
						line-height: 23px;
						margin-bottom: 4px;
						padding-left: 14px;
						&:after {
							position: absolute;
							display: block;
							content: "";
							width: 6px;
							height: 6px;
							left: 0;
							top: calc(50% - 3px);
							border-radius: 6px;
							background: #d1d8e1;
						}
						&.blue {
							&:after {
								background: #005bff;
							}
						}
						span {
							position: absolute;
							display: block;
							right: 0;
							top: 0;
							font-weight: 400;
							span {
								position: relative;
								display: inline-block;
								color: #94a0b1;
							}
						}
					}
					.form-container-right-item {
						position: relative;
						display: block;
						font-size: 14px;
						line-height: 23px;
						margin-bottom: 4px;
						span {
							position: absolute;
							display: block;
							right: 0;
							top: 0;
							font-weight: 400;
						}
					}
					.form-container-right-progress {
						position: relative;
						display: block;
						height: 6px;
						border-radius: 3px;
						overflow: hidden;
						background: #e2e8f0;
						margin-top: 19px;
						margin-bottom: 24px;
						.form-container-right-progressline {
							position: absolute;
							height: 6px;
							border-radius: 3px;
							background: #005bff;
						}
					}
					.form-container-right-divider {
						position: relative;
						display: block;
						margin: 24px -32px;
						height: 1px;
						background: #e2e8f0;
					}
					.border-button.button {
						margin-bottom: 8px;
					}
					.remove-video-button {
						position: relative;
						display: block;
						font-size: 15px;
						line-height: 24px;
						color: #94a0b1;
						text-align: center;
						cursor: pointer;
						margin-top: 26px;
						svg {
							position: relative;
							display: inline-block;
							vertical-align: top;
							margin-right: 4px;
						}
						&:hover {
							color: #005bff;
							path {
								fill: #005bff;
							}
						}
					}
					.form-container-upload {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px dashed #d1d8e1;
						border-radius: 80px;
						width: 121px;
						height: 121px;
						box-sizing: border-box;
						margin: 0 auto;
						cursor: pointer;
						&:hover {
							path {
								fill: #005bff;
							}
						}
					}
					.add-photo-button {
						position: relative;
						display: block;
						font-size: 15px;
						line-height: 24px;
						color: #94a0b1;
						text-align: center;
						cursor: pointer;
						margin-top: 26px;
						svg {
							position: relative;
							display: inline-block;
							vertical-align: top;
							margin-right: 4px;
						}
						&:hover {
							color: #005bff;
							path {
								fill: #005bff;
							}
						}
					}
				}
				.form-container {
					position: relative;
					display: block;
					width: 100%;
					&:nth-child(2) {
						.form-container-block {
							padding: 24px;
						}
						.product-stat-title {
							font-weight: 600;
							font-size: 18px;
							line-height: 23px;
							margin-bottom: 24px;
						}
						.ohvat-block {
							position: relative;
							display: block;
							width: 100%;
							height: 139px;
							background: #e9d6ff;
							border-radius: 16px;
							padding: 24px;
							box-sizing: border-box;
							b {
								font-weight: 600;
								font-size: 24px;
								line-height: 31px;
							}
							small {
								position: absolute;
								display: block;
								font-weight: 400;
								font-size: 14px;
								line-height: 20px;
								left: 24px;
								bottom: 24px;
								margin-bottom: 0;
							}
							&:nth-child(2) {
								background: #ffe6a6;
							}
						}
					}
					.ohvat-block {
						position: relative;
						display: block;
						width: 100%;
						height: 139px;
						background: #7027c2;
						border-radius: 16px;
						padding: 24px;
						box-sizing: border-box;
						color: #fff;
						b {
							font-weight: 600;
							font-size: 24px;
							line-height: 31px;
						}
						small {
							position: absolute;
							display: block;
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
							left: 24px;
							bottom: 24px;
							color: #fff;
							margin-bottom: 0;
						}
						svg {
							position: absolute;
							top: 28px;
							right: 29px;
						}
						&:nth-child(2) {
							background: #ff5eb2;
						}
					}
					.form-container-block {
						position: relative;
						display: block;
						padding: 32px;
						box-sizing: border-box;
						margin-bottom: 16px;
						background: #ffffff;
						border-radius: 16px;
						.flex-block:last-child {
							.form-field {
								margin-bottom: 0;
							}
						}
						.form-container-block-title {
							position: relative;
							margin-bottom: 32px;
							font-size: 15px;
							line-height: 24px;
							b {
								position: relative;
								display: block;
								font-weight: 600;
								font-size: 18px;
								line-height: 24px;
								//margin-bottom: 16px;
							}
						}
						.form-container-block-subtitle {
							position: relative;
							display: block;
							font-weight: 600;
							font-size: 15px;
							margin-bottom: 24px;
							margin-top: 32px;
						}
						.form-container-block-peoples {
							position: relative;
							display: block;
							font-size: 0;
							margin-bottom: 8px;
							.form-container-block-people {
								position: relative;
								display: inline-block;
								margin-right: 8px;
								margin-bottom: 8px;
								background: #f4f4f8;
								border-radius: 80px;
								cursor: pointer;
								&:last-child {
									margin-bottom: 16px;
								}
								span {
									position: relative;
									display: block;
									padding: 0 35px 0 18px;
									font-size: 14px;
									line-height: 40px;
								}
								&:hover {
									color: #005bff;
									svg {
										path {
											fill: #005bff;
										}
									}
								}
								.remove-people {
									position: absolute;
									display: block;
									font-size: 0;
									right: 10px;
									top: 10px;
									height: 20px;
									cursor: pointer;
								}
							}
						}
						.form-container-block-seasons {
							position: relative;
							display: block;
							font-size: 0;
							.form-container-block-season {
								position: relative;
								border: 1px solid #e2e8f0;
								border-radius: 16px;
								cursor: pointer;
								margin-bottom: 8px;
								background: #fff;
								&.open {
									.form-container-block-season-header {
										.form-container-block-season-arrow {
											transform: rotate(180deg);
										}
									}
								}
								.form-container-block-season-move {
									position: absolute;
									display: block;
									left: 24px;
									top: 24px;
								}
								.form-container-block-season-header {
									.form-container-block-season-title {
										position: relative;
										height: 72px;
										font-size: 15px;
										font-weight: 600;
										margin-left: 64px;
										line-height: 72px;
									}
									.form-container-block-season-arrow {
										position: absolute;
										right: 32px;
										top: 24px;
									}
									&:hover {
										color: #005bff;
										.form-container-block-season-arrow {
											svg {
												path {
													fill: #005bff;
												}
											}
										}
									}
								}
								.season-control {
									position: relative;
									display: flex;
									justify-content: space-between;
									align-items: center;
									margin: 16px 24px 24px 24px;
									.season-control-buttons {
										position: relative;
										display: flex;
										justify-content: space-between;
										gap: 32px;
									}
									.season-control-button {
										position: relative;
										display: block;
										line-height: 24px;
										font-weight: 400;
										font-size: 15px;
										color: #94a0b1;
										&:hover {
											color: #005bff;
										}
									}
								}
								.form-container-block-season-container {
									position: relative;
									display: none;
									.form-container-block-season-episodes {
										position: relative;
										margin: 0 24px;
										.season-episode-control {
											position: absolute;
											display: flex;
											justify-content: space-between;
											right: 0;
											top: calc(50% - 20px);
											gap: 8px;
											.season-episode-control-item {
												position: relative;
												display: flex;
												width: 40px;
												height: 40px;
												justify-content: center;
												align-items: center;
												cursor: pointer;
												&:hover {
													path {
														fill: #005bff;
													}
												}
											}
										}
										.form-container-block-season-episode {
											position: relative;
											display: block;
											height: 48px;
											box-sizing: border-box;
											line-height: 48px;
											border-bottom: 1px solid #e2e8f0;
											cursor: default;
											background: #fff;
											&:last-child {
												border-bottom: none;
											}
											.form-container-block-season-episode-title {
												position: relative;
												display: inline-block;
												font-weight: 400;
												font-size: 15px;
												padding-left: 40px;
												cursor: pointer;
												&:hover {
													color: #005bff;
												}
											}
											.form-container-block-season-episode-move {
												position: absolute;
												display: block;
												left: 0;
												height: 24px;
												top: calc(50% - 12px);
												cursor: pointer;
											}
										}
									}
								}
							}
						}
						.add-people {
							line-height: 36px;
							font-weight: 600;
							width: 127px;
							white-space: nowrap;
							padding-left: 40px;
							padding-right: 20px;
							box-sizing: border-box;
							margin-top: -8px;
							&:after {
								position: absolute;
								display: block;
								width: 16px;
								height: 16px;
								content: "";
								background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
								left: 16px;
								top: 10px;
							}
						}
						.add-season {
							line-height: 36px;
							font-weight: 600;
							width: 173px;
							white-space: nowrap;
							padding-left: 40px;
							padding-right: 20px;
							box-sizing: border-box;
							margin-top: -8px;
							&:after {
								position: absolute;
								display: block;
								width: 16px;
								height: 16px;
								content: "";
								background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
								left: 16px;
								top: 10px;
							}
						}
						.add-episode {
							line-height: 36px;
							font-weight: 600;
							width: 178px;
							white-space: nowrap;
							padding-left: 40px;
							padding-right: 20px;
							box-sizing: border-box;
							&:after {
								position: absolute;
								display: block;
								width: 16px;
								height: 16px;
								content: "";
								background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
								left: 16px;
								top: 10px;
							}
						}
					}
				}
			}
			&.blogers-page {
				background: #fff;
				.list-filter {
					margin-bottom: 24px !important;
					z-index: 1;
				}
				.list-title {
					margin-bottom: 25px;
				}
				.blogers-type-filter {
					position: relative;
					display: flex;
					justify-content: flex-start;
					.list-title-select {
						position: relative;
						display: block;
						.list-title-select-btn {
							cursor: pointer;
							font-size: 15px;
							line-height: 24px;
							svg {
								position: relative;
								display: inline-block;
								vertical-align: top;
								top: -1px;
							}
						}
						&:hover {
							.list-title-select-btn {
								color: #005bff;
								svg {
									path {
										fill: #005bff;
									}
								}
							}
						}
						&.active {
							.list-title-select-submenu {
								display: block;
							}
						}
						.list-title-select-submenu {
							position: absolute;
							display: none;
							left: 0;
							top: 32px;
							padding: 16px 20px;
							background: #ffffff;
							box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
							border-radius: 8px;
							z-index: 10;
							.list-title-select-submenu-item {
								position: relative;
								display: block;
								font-weight: 400;
								font-size: 14px;
								line-height: 22px;
								white-space: nowrap;
								cursor: pointer;
								margin-bottom: 8px;
								&:last-child {
									margin-bottom: 0;
								}
								&:hover {
									color: #005bff;
								}
							}
						}
					}
				}
				.blogers-list-style {
					position: relative;
					display: flex;
					align-items: center;
					top: 12px;
					.blogers-list-style-btn {
						position: relative;
						display: block;
						cursor: pointer;
						&:hover {
							svg {
								path {
									fill: #005bff;
								}
							}
						}
					}
				}
				.page-bookmarks {
					position: relative;
					display: flex;
					gap: 32px;
					border-bottom: 1px solid #e2e8f0;
					margin-bottom: 29px;
					.page-bookmark {
						position: relative;
						cursor: pointer;
						display: block;
						font-weight: 400;
						font-size: 15px;
						line-height: 160%;
						align-items: center;
						color: #212121;
						padding-bottom: 18px;
						box-sizing: border-box;
						text-decoration: none;
						&:hover {
							border-bottom: 2px solid #005bff;
						}
						&.active {
							border-bottom: 2px solid #005bff;
						}
					}
				}
				.blogers-list {
					position: relative;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					gap: 16px;
					align-items: stretch;
					margin-top: 32px;
					margin-bottom: 48px;
					.blogers-list-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 32px 24px 24px;
						box-sizing: border-box;
						background: #ffffff;
						border: 1px solid #e2e8f0;
						border-radius: 24px;
						font-size: 0;
						width: calc(25% - 12px);
						position: relative;
						@media (max-width: 1241px) {
							padding: 32px 16px 16px 24px;
						}
						.blogger-chat-btn {
							position: absolute;
							right: 8px;
							top: 8px;
						}
						img,
						.bloger-avatar {
							position: relative;
							display: block;
							margin: 0 auto 25px;
							width: 120px;
							height: 120px;
							border-radius: 400px;
							object-fit: cover;
							background: #e2e8f0;
						}
						.bloger-name {
							font-weight: 600;
							font-size: 18px;
							min-height: 18px;
							line-height: 130%;
							margin-bottom: 6px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.bloger-type {
							font-weight: 400;
							font-size: 14px;
							line-height: 160%;
							margin-bottom: 16px;
							// white-space: nowrap;
							// overflow: hidden;
							// text-overflow: ellipsis;
						}
						.bloger-rating {
							position: relative;
							display: inline-block;
							margin-right: 9px;
							padding: 0 12px;
							background: #f4f4f8;
							border-radius: 80px;
							font-weight: 400;
							font-size: 15px;
							line-height: 36px;
							margin-bottom: 16px;
							.tooltip {
								max-width: 170px;
							}
							svg {
								position: relative;
								display: inline-block;
								vertical-align: middle;
								margin-right: 4px;
								top: -2px;
							}
						}
						.bloger-conversion {
							position: relative;
							display: inline-block;
							margin-right: 9px;
							padding: 0 12px;
							background: #f4f4f8;
							border-radius: 80px;
							font-weight: 400;
							font-size: 15px;
							line-height: 36px;
							margin-bottom: 16px;
							.tooltip {
								max-width: 194px;
							}
							svg {
								position: relative;
								display: inline-block;
								vertical-align: middle;
								margin-right: 4px;
								top: -2px;
							}
						}
						.bloger-counter {
							position: relative;
							display: flex;
							justify-content: space-between;
							font-weight: 400;
							font-size: 13px;
							line-height: 160%;
							span {
								color: #94a0b1;
							}
						}
						.bloger-order-btn {
							align-self: stretch;
							background: #005bff;
							border-radius: 24px;
							box-sizing: border-box;
							color: #fff;
							cursor: pointer;
							display: block;
							font-size: 15px;
							height: 48px;
							line-height: 48px;
							position: relative;
							text-align: center;
							margin-top: 16px;
							&:hover {
								background: #004ace;
							}
						}
					}
				}
			}
		}
		&.profile-page {
			background-color: #f4f4f8;
		}
	}
	#modal {
		position: fixed;
		display: none;
		justify-content: center;
		align-items: center;
		overflow-y: scroll;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 100;
		background: rgba(0, 0, 0, 0.4);
		&::-webkit-scrollbar {
			display: none;
		}
		&.active {
			display: flex;
			flex-wrap: wrap;
		}
		.modal-container {
			position: relative;
			display: block;
			padding: 50px 0;
		}
		.modal-field {
			position: relative;
			display: block;
			padding: 32px;
			background: #ffffff;
			box-sizing: border-box;
			border-radius: 16px;
			&.active {
				display: block;
			}
			&.add-ads-success {
				width: 439px;
				.modal-buttons {
					justify-content: center;
					margin-top: 24px;
					.button {
						width: 155px;
					}
				}
			}
			.add-ads-modal-text {
				position: relative;
				display: block;
				text-align: center;
				font-weight: 600;
				font-size: 18px;
				line-height: 23px;
				svg {
					position: relative;
					display: block;
					margin: 0 auto 25px;
				}
			}
			.modal-field-close {
				position: absolute;
				display: block;
				right: 40px;
				top: 40px;
				cursor: pointer;
				&:hover {
					path {
						fill: #005bff;
					}
				}
			}
			.invisible {
				position: absolute;
				visibility: hidden;
				&.visible {
					position: relative;
					visibility: visible;
				}
			}
			.modal-field-title {
				position: relative;
				font-size: 24px;
				line-height: 40px;
				font-weight: 600;
				margin-bottom: 32px;
			}
			.modal-buttons {
				position: relative;
				display: flex;
				justify-content: space-between;
				gap: 8px;
				height: 59px;
				.modal-video-button {
					position: relative;
					display: block;
					width: 184px;
					border: 2px solid #d1d8e1;
					border-radius: 4px;
					box-sizing: border-box;
					cursor: pointer;
					padding-left: 20px;
					font-size: 15px;
					font-weight: 600;
					line-height: 55px;
					&:after {
						position: absolute;
						display: block;
						content: "";
						width: 24px;
						height: 24px;
						right: 20px;
						top: 16px;
						background: url("/public/images/icons/ui/different/gray/movie.svg");
					}
					&:nth-child(2) {
						&:after {
							background: url("/public/images/icons/ui/different/gray/tv.svg");
						}
					}
					&:hover {
						border-color: #005bff;
					}
				}
			}
		}
	}
	#create-ads-bg {
		position: fixed;
		display: block;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		visibility: hidden;
		transition: all 300ms linear;
		&.active {
			visibility: visible;
			opacity: 1;
		}
	}
	#create-ads-form {
		position: fixed;
		right: -699px;
		top: 0;
		width: 580px;
		box-sizing: border-box;
		height: 100vh;
		z-index: 1000;
		background: #fff;
		overflow: hidden;
		transition: all 300ms ease-in;
		&.active {
			transition: all 300ms ease-out;
			right: 0;
		}
		.form-container-block-title {
			position: relative;
			margin-bottom: 18px;
			font-size: 15px;
			line-height: 24px;
			b {
				position: relative;
				display: block;
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				margin-bottom: 16px;
			}
		}
		.create-ads-scroller {
			box-sizing: border-box;
			display: block;
			height: 100%;
			overflow-x: hidden;
			overflow-y: scroll;
			padding: 0 32px 0 32px;
			position: absolute;
			width: 100%;
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background: #94a0b14d;
				border-radius: 2px;
			}
			&::-webkit-scrollbar-track {
				background: none;
			}
			small {
				position: relative;
				display: block;
				color: #94a0b1;
				font-weight: 400;
				font-size: 15px;
				margin-bottom: 24px;
			}
		}
		.create-ads-close {
			position: absolute;
			display: block;
			right: 24px;
			top: 28px;
			cursor: pointer;
			&:hover {
				path {
					fill: #005bff;
				}
			}
		}
		.create-ads-title {
			position: relative;
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
			margin-bottom: 40px;
			margin-top: 24px;
		}
		.create-ads-buttons {
			display: flex;
			gap: 8px;
			justify-content: space-between;
			position: relative;
			box-sizing: border-box;
			margin-left: 0;
			padding-bottom: 32px;
			.button:nth-child(1) {
				width: 135px;
			}
			.button:nth-child(2) {
				min-width: 197px;
			}
		}
		.click-price {
			position: relative;
			width: 100%;
			height: 72px;
			border: 1px solid #e2e8f0;
			border-radius: 16px;
			box-sizing: border-box;
			line-height: 72px;
			padding: 0 24px;
			display: flex;
			justify-content: space-between;
			font-weight: 600;
			font-size: 15px;
			b {
				font-size: 24px;
			}
		}
	}
}

@keyframes loaderAnimation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

body {
	/* @group Base */
	.chosen-container {
		display: flex;
		background: #f4f4f8;
		border: 1px solid #f4f4f8;
		border-radius: 8px;
		box-sizing: border-box;
		color: #212121;
		font-family: Averta CY;
		font-size: 15px;
		min-height: 54px;
		height: auto;
		outline: none;
		position: relative;
		width: 100% !important;
		padding: 11px 16px;
		padding-right: 80px;
		padding-bottom: 8px;
		// align-items: flex-end;
		flex-wrap: wrap;
	}
	.input-field.search-block .chosen-container {
		padding-left: 52px;
		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 20px;
			height: 20px;
			left: 16px;
			top: 16px;
			background: url("/public/images/icons/ui/search.svg") no-repeat;
			background-size: contain;
		}
	}
	.chosen-container .clear-chosen-container {
		position: absolute;
		display: none;
		width: 24px;
		height: 24px;
		right: 44px;
		top: 14px;
		cursor: pointer;
	}
	.chosen-container .clear-chosen-container.active {
		display: block;
	}
	.chosen-container:hover {
		border: 1px solid #d1d8e1;
	}
	.chosen-container:after {
		// background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
		content: url("/public/images/icons/ui/navigation/drop_down_filled.svg");
		display: block;
		height: 24px;
		pointer-events: none;
		position: absolute;
		right: 16px;
		top: 14px;
		width: 24px;
		&.active {
			transform: rotate(180deg);
		}
	}
	.chosen-container {
		&.input-disabled::after {
			content: url("/public/images/icons/ui/navigation/gray/drop_down_filled.svg");
		}
	}
	.chosen-container {
		&.active:after {
			transform: rotate(180deg);
		}
	}
	.input-field.loading {
		pointer-events: none;
	}
	.input-field.loading .chosen-container:after {
		background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
		background-size: cover;
		animation: 1s linear infinite loaderAnimation;
	}
	.chosen-container * {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.chosen-container .chosen-drop {
		position: absolute;
		z-index: 1010;
		width: 100%;
		border-top: 0;
		background: #fff;
		clip: rect(0, 0, 0, 0);
		-webkit-clip-path: inset(100% 100%);
		clip-path: inset(100% 100%);
		left: 0;
		background: #fff;
		border-radius: 8px;
		box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
		cursor: default;
		overflow-x: hidden;
		top: calc(100% + 8px);
	}
	.chosen-container.chosen-with-drop .chosen-drop {
		clip: auto;
		-webkit-clip-path: none;
		clip-path: none;
	}
	.chosen-container a {
		cursor: pointer;
	}
	.chosen-container .search-choice .group-name,
	.chosen-container .chosen-single .group-name {
		margin-right: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: normal;
		color: #999999;
	}
	.chosen-container .search-choice .group-name:after,
	.chosen-container .chosen-single .group-name:after {
		content: ":";
		padding-left: 2px;
		vertical-align: top;
	}
	/* @end */
	/* @group Single Chosen */
	.chosen-container-single .chosen-single {
		position: relative;
		display: block;
		overflow: hidden;
		//padding: 0 0 0 8px;
		//height: 25px;
		//border: 1px solid #aaa;
		//border-radius: 5px;
		//background-color: #fff;
		//background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), color-stop(50%, #f6f6f6), color-stop(52%, #eee), to(#f4f4f4));
		//background: linear-gradient(#fff 20%, #f6f6f6 50%, #eee 52%, #f4f4f4 100%);
		//background-clip: padding-box;
		//-webkit-box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
		//box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
		//color: #444;
		text-decoration: none;
		white-space: nowrap;
		line-height: 30px;
	}
	.chosen-container-single .chosen-default {
		color: #94a0b1;
	}
	.chosen-container-single .chosen-single span {
		display: block;
		overflow: hidden;
		margin-right: 26px;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
	}
	.chosen-container-single .chosen-single-with-deselect span {
		margin-right: 38px;
	}
	.chosen-container-single .chosen-single abbr {
		position: absolute;
		top: 6px;
		right: 26px;
		display: block;
		width: 12px;
		height: 12px;
		//background: url("chosen-sprite.png'") -42px 1px no-repeat;
		font-size: 1px;
	}
	.chosen-container-single .chosen-single abbr:hover {
		background-position: -42px -10px;
	}
	.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
		background-position: -42px -10px;
	}
	.chosen-container-single .chosen-single div {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 18px;
		height: 100%;
	}
	.chosen-container-single .chosen-single div b {
		display: block;
		width: 100%;
		height: 100%;
		//background: url("chosen-sprite.png'") no-repeat 0px 2px;
	}
	.chosen-container-single .chosen-search {
		position: relative;
		z-index: 1010;
		margin: 0;
		padding: 0;
		white-space: nowrap;
	}
	.chosen-container-single .chosen-search input[type="text"] {
		border-radius: 10px;
		font-family: sans-serif;
		font-size: 1em;
		height: auto;
		line-height: normal;
		margin: 0;
		outline: 0;
		padding: 14px 20px;
		background: #fff;
		width: calc(100% - 40px);
		border: 0;
	}
	.chosen-container-single .chosen-drop {
		margin-top: -1px;
		border-radius: 0 0 4px 4px;
		background-clip: padding-box;
	}
	.chosen-container-single.chosen-container-single-nosearch .chosen-search {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		-webkit-clip-path: inset(100% 100%);
		clip-path: inset(100% 100%);
	}
	/* @end */
	/* @group Results */
	.chosen-container .chosen-results {
		color: #444;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		max-height: 240px;
		-webkit-overflow-scrolling: touch;
		width: calc(100% + 20px);
	}
	.chosen-container .chosen-results li {
		//display: none;
		//margin: 0;
		//padding: 5px 6px;
		//list-style: none;
		//line-height: 15px;
		//word-wrap: break-word;
		//-webkit-touch-callout: none;
		cursor: pointer;
		display: none;
		font-size: 15px;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		position: relative;
	}
	.chosen-container .chosen-results li.active-result {
		display: list-item;
		cursor: pointer;
	}
	.chosen-container .chosen-results li.disabled-result {
		display: list-item;
		color: #ccc;
		cursor: default;
	}
	.chosen-container .chosen-results li.highlighted {
		background: #f4f4f8;
		color: #005bff;
	}
	.chosen-container .chosen-results li.no-results {
		color: #777;
		display: list-item;
		background: #f4f4f4;
	}
	.chosen-container .chosen-results li.group-result {
		display: list-item;
		font-weight: bold;
		cursor: default;
	}
	.chosen-container .chosen-results li.group-option {
		padding-left: 15px;
	}
	.chosen-container .chosen-results li em {
		font-style: normal;
		text-decoration: underline;
	}
	/* @end */
	/* @group Multi Chosen */
	.chosen-container-multi .chosen-choices {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: 100%;
		height: auto;
		cursor: text;
	}
	.chosen-container-multi .chosen-choices li {
		float: left;
		list-style: none;
	}
	.chosen-container-multi .chosen-choices li.search-field {
		margin: 0;
		padding: 0;
		white-space: nowrap;
	}
	.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
		margin: 1px 0;
		padding: 0;
		height: 25px;
		outline: 0;
		border: 0 !important;
		background: transparent !important;
		-webkit-box-shadow: none;
		box-shadow: none;
		color: #94a0b1;
		font-family: "Averta CY";
		font-size: 15px;
		line-height: normal;
		border-radius: 0;
		width: 25px;
		outline: none !important;
	}
	.chosen-container-multi .chosen-choices li.search-field input[type="text"]:focus {
		border: 0;
		outline: none;
	}
	.chosen-container-multi .chosen-choices li.search-choice {
		position: relative;
		max-width: 100%;
		color: #212121;
		font-size: 13px;
		line-height: 29px;
		cursor: default;
		background: #d1d8e1;
		border-radius: 6px;
		padding: 0 24px 0 8px;
		box-sizing: border-box;
		margin-right: 4px;
		margin-bottom: 4px;
	}
	.chosen-container-multi .chosen-choices li.search-choice span {
		word-wrap: break-word;
	}
	.chosen-container-multi .search-choice-close {
		position: absolute;
		top: 6px;
		right: 5px;
		display: block;
		width: 16px;
		height: 16px;
		background: url("/public/images/icons/ui/navigation/close_small.svg") no-repeat;
		background-size: 16px 16px;
		font-size: 1px;
	}
	.chosen-container-multi .chosen-choices li.search-choice-disabled {
		padding-right: 5px;
		border: 1px solid #ccc;
		background-color: #e4e4e4;
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			color-stop(20%, #f4f4f4),
			color-stop(50%, #f0f0f0),
			color-stop(52%, #e8e8e8),
			to(#eee)
		);
		background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
		color: #666;
	}
	.chosen-container-multi .chosen-choices li.search-choice-focus {
		background: #d4d4d4;
	}
	.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
		background-position: -42px -10px;
	}
	.chosen-container-multi .chosen-results {
		margin: 0;
		padding: 0;
	}
	.chosen-container-multi .chosen-drop .result-selected {
		display: list-item;
		color: #ccc;
		cursor: default;
	}
	/* @end */
	/* @group Active  */
	.chosen-container-active .chosen-single {
	}
	.chosen-with-drop:after {
		transform: rotate(180deg);
	}
	.chosen-container-active.chosen-with-drop .chosen-single {
		//border: 1px solid #aaa;
		//border-bottom-right-radius: 0;
		//border-bottom-left-radius: 0;
		//background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #eee), color-stop(80%, #fff));
		//background-image: linear-gradient(#eee 20%, #fff 80%);
		//-webkit-box-shadow: 0 1px 0 #fff inset;
		//box-shadow: 0 1px 0 #fff inset;
	}
	.chosen-container-active.chosen-with-drop .chosen-single div {
		border-left: none;
		background: transparent;
	}
	.chosen-container-active.chosen-with-drop .chosen-single div b {
		background-position: -18px 2px;
	}
	.chosen-container-active .chosen-choices {
		//border: 1px solid #5897fb;
		//-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
		//box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}
	.chosen-container-active .chosen-choices li.search-field input[type="text"] {
		color: #222 !important;
	}
	/* @end */
	/* @group Disabled Support */
	.chosen-disabled {
		opacity: 0.5 !important;
		cursor: default;
	}
	.chosen-disabled .chosen-single {
		cursor: default;
	}
	.chosen-disabled .chosen-choices .search-choice .search-choice-close {
		cursor: default;
	}
	/* @end */
	/* @group Right to Left */
	.chosen-rtl {
		text-align: right;
	}
	.chosen-rtl .chosen-single {
		overflow: visible;
		padding: 0 8px 0 0;
	}
	.chosen-rtl .chosen-single span {
		margin-right: 0;
		margin-left: 26px;
		direction: rtl;
	}
	.chosen-rtl .chosen-single-with-deselect span {
		margin-left: 38px;
	}
	.chosen-rtl .chosen-single div {
		right: auto;
		left: 3px;
	}
	.chosen-rtl .chosen-single abbr {
		right: auto;
		left: 26px;
	}
	.chosen-rtl .chosen-choices li {
		float: right;
	}
	.chosen-rtl .chosen-choices li.search-field input[type="text"] {
		direction: rtl;
	}
	.chosen-rtl .chosen-choices li.search-choice {
		margin: 3px 5px 3px 0;
		padding: 3px 5px 3px 19px;
	}
	.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
		right: auto;
		left: 4px;
	}
	.chosen-rtl.chosen-container-single .chosen-results {
		margin: 0 0 4px 4px;
		padding: 0 4px 0 0;
	}
	.chosen-rtl .chosen-results li.group-option {
		padding-right: 15px;
		padding-left: 0;
	}
	.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
		border-right: none;
	}
	.chosen-rtl .chosen-search input[type="text"] {
		padding: 4px 5px 4px 20px;
		direction: rtl;
	}
	.chosen-rtl.chosen-container-single .chosen-single div b {
		background-position: 6px 2px;
	}
	.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
		background-position: -12px 2px;
	}
	/* @end */
	/* @group Retina compatibility */
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
		only screen and (min-resolution: 144dpi),
		only screen and (min-resolution: 1.5dppx) {
		.chosen-rtl .chosen-search input[type="text"],
		.chosen-container-single .chosen-single abbr,
		.chosen-container-single .chosen-single div b,
		.chosen-container-single .chosen-search input[type="text"],
		.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
		.chosen-container .chosen-results-scroll-down span,
		.chosen-container .chosen-results-scroll-up span {
		}
	}
	/* @end */
	.chosen-drop {
		.select-block-list-bar {
			position: absolute;
			display: block;
			width: 4px;
			pointer-events: none;
			top: 16px;
			right: 8px;
			height: calc(100% - 32px);
			border-radius: 2px;
			overflow: hidden;
			.select-block-list-thumb {
				position: absolute;
				height: 30%;
				width: 100%;
				left: 0;
				top: 0;
				background: #94a0b1;
				opacity: 0.3;
				border-radius: 2px;
			}
		}
		.select-block-list-scroll {
			position: relative;
			display: block;
			width: calc(100% + 20px);
			overflow-y: scroll;
			max-height: 280px;
			.select-block-item {
				position: relative;
				display: block;
				height: 40px;
				font-size: 15px;
				line-height: 40px;
				padding: 0 20px;
				cursor: pointer;
				&:first-child {
					margin-top: 16px;
				}
				&:last-child {
					margin-bottom: 16px;
				}
				&:hover {
					background: #f4f4f8;
					span {
						color: #005bff;
					}
				}
				span {
					position: absolute;
					display: block;
					width: calc(100% - 60px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
	#ui-datepicker-div {
		position: absolute;
		display: none;
		right: 40px !important;
		z-index: 1000 !important;
		top: 280px !important;
		left: auto !important;
		background: #ffffff;
		/* Element shadow */
		height: auto;
		width: 328px;
		box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
		border-radius: 16px;
		padding-bottom: 12px;
		&:after {
			position: absolute;
			display: block;
			content: "";
			width: 100%;
			height: 1px;
			background: #f4f4f8;
			left: 0;
			top: 105px;
		}
		.ui-datepicker-header {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			.ui-datepicker-prev {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				left: 6px;
				top: 14px;
				line-height: 24px;
				font-size: 0;
				width: 44px;
				height: 44px;
				&:after {
					position: relative;
					display: block;
					content: "";
					font-size: 15px;
					height: 24px;
					line-height: 24px;
					width: 24px;
					top: 0;
					background: url("/public/images/icons/ui/navigation/drop_previous.svg") no-repeat 50% 50%;
					cursor: pointer;
				}
			}
			.ui-datepicker-next {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 6px;
				top: 14px;
				line-height: 24px;
				font-size: 0;
				width: 44px;
				height: 44px;
				&:after {
					position: relative;
					display: block;
					content: "";
					font-size: 15px;
					height: 24px;
					line-height: 24px;
					width: 24px;
					top: 0;
					background: url("/public/images/icons/ui/navigation/drop_next.svg") no-repeat 50% 50%;
					cursor: pointer;
				}
			}
			.ui-datepicker-title {
				position: absolute;
				top: 24px;
				line-height: 24px;
				width: calc(100% - 60px);
				left: 30px;
				font-size: 15px;
				text-align: center;
				z-index: -1;
			}
		}
		.ui-datepicker-calendar {
			width: 100%;
			padding: 0 15px;
			padding-top: 72px;
			border-spacing: 1px;
			th {
				font-weight: 400;
				font-size: 15px;
				line-height: 32px;
				padding-bottom: 8px;
				span {
					position: relative;
					display: block;
					top: -10px;
				}
			}
			td {
				font-weight: 400;
				font-size: 15px;
				line-height: 32px;
				&.ui-state-disabled span {
					position: relative;
					display: block;
					text-align: center;
					box-sizing: border-box;
					border: #fff solid 1px;
					color: #d1d8e1;
				}
				a {
					position: relative;
					display: block;
					text-align: center;
					box-sizing: border-box;
					border: #fff solid 1px;
					border-radius: 4px;
					color: #212121;
					text-decoration: none;
					&:hover {
						border: #005bff solid 1px;
					}
					&.ui-state-active {
						border: #005bff solid 1px;
						background: #005bff;
						color: #fff;
					}
				}
				&.dp-highlight {
					a {
						background: #abcdff;
						border: #abcdff solid 1px;
						color: #005bff;
						&.ui-state-active {
							border: #005bff solid 1px;
							background: #005bff;
							color: #fff;
						}
					}
				}
			}
		}
	}
}

#block-on-mobile {
	position: fixed;
	display: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #fff url("/public/images/mobile.png") no-repeat 50% 50%;
	background-size: 327px auto;
	z-index: 9999;
	#mobile-logo {
		position: absolute;
		display: block;
		top: 24px;
		left: calc(50% - 80px);
	}
	@media screen and (max-width: 1024px) {
		display: block;
	}
	@media screen and (max-height: 400px) {
		#mobile-logo {
			display: none;
		}
	}
}

.select-product-dropdown {
	display: block;
	z-index: 999;
	width: 310px;
	padding: 12px 0;
	background: #fff;
	position: absolute;
	right: 0;
	top: calc(100% + 8px);
	border-radius: 8px;
	box-shadow: 8px 8px 48px 0px rgba(46, 69, 102, 0.2);
	cursor: pointer;
	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}
	ul {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			padding: 4px 20px;
			font-family: Averta CY;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
			&:hover {
				color: #005bff;
				background-color: #f4f4f8;
				svg {
					path {
						fill: #005bff;
					}
				}
			}
		}
	}
}
