.T-center {
	text-align: center;
}

.T-right {
	text-align: right;
}

.T-nowrap {
	white-space: nowrap;
}

.T- {
	&grey400 {
		color: $grey400;
	}
	&blue {
		color: var(--blue);
	}
	&red {
		color: #ed0a34;
	}
}

@mixin T15-bold {
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
}

@mixin T15 {
	font-size: 15px;
	line-height: 24px;
}

@mixin T16 {
	font-size: 16px;
	line-height: 19px;
}

@mixin T14 {
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
}

@mixin T12 {
	font-size: 12px;
	line-height: 17px;
	font-weight: 400;
}

@mixin T14-bold {
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
}

@mixin T14-list {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

@mixin H5 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

@mixin H4 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

@mixin H3 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

.T13 {
	font-size: 13px;
	line-height: 21px;
	&-list {
		font-size: 13px;
		line-height: 18px;
		letter-spacing: 0.1px;
	}
	&-bold {
		font-size: 13px;
		line-height: 21px;
		font-weight: 600;
	}
}

.T15,
.T15-bold {
	@include T15();
}

.T15-list {
	@include T15();
	line-height: 21px;
}

.T14,
.T14-bold {
	@include T14();
}

.T14-list {
	@include T14-list();
}

.T16,
.T16-bold {
	@include T16();
}

.T15,
.T16,
.T14 {
	&-bold {
		font-weight: 600;
	}
}

.T12 {
	font-size: 12px;
	line-height: 17px;
	font-weight: 400;
}

.H5 {
	@include H5();
}

.H3 {
	@include H3();
	@media screen and (max-width: 1024px) {
		font-size: 24px;
		line-height: 28px;
	}
}

.H4 {
	@include H4();
	@media screen and (max-width: 1024px) {
		font-size: 20px;
		line-height: 26px;
	}
}

.text,
.T {
	&-grey {
		color: #94a0b1;
	}
	&-blue {
		color: $blue;
	}
	&-white {
		color: #fff;
	}
	&-center {
		text-align: center;
	}
	&-error {
		color: #ed0a34;
	}
}

.text-center {
	text-align: center;
}
