.modal {
    &-content {
        // min-width: 440px;
    }
    &-content__wrapper {
        box-sizing: border-box;
        padding: 32px;
        position: relative;
        background: #fff;
        border-radius: 16px;
        &--centered {
            @include col-center;
        }
    }
    &-header {
        width: 100%;
        @include row-end;
        &--with-title {
            @include row-between;
            align-items: flex-start;
            margin-bottom: 32px;
        }
    }
    &-close-btn {
    }
    &-title {
        @include H3;
    }
    &-subtitle {
        @include H5;
        margin-bottom: 24px;
    }
    &__bottom-btns {
        width: 100%;
        column-gap: 18px;
        @include row($justify: space-between);
        position: relative;
    }
}
