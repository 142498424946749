.category-popup {
	max-width: 1300px;
	min-width: 1160px;
	min-height: 640px;
	@media (max-width: 1200px) {
		min-width: 1000px;
	}
	.modal-title {
	}
	&__search-input {
		font-size: 16px;
		line-height: 30px;
		font-weight: 400;
		margin-left: 30px;
		outline: none;
		width: 300px;
		margin-top: 9px;
		border-width: 0px 0px 1px;
		border-bottom-style: solid;
		border-bottom-color: rgb(204, 204, 204);
	}
	&__content {
		position: relative;
		&:before {
			@include absolute(
				$width: calc(100% + 32px * 2),
				$height: 1px,
				$bgColor: $grey200,
				$left: -32px,
				$top: -12px
			);
		}
		li {
			cursor: pointer;
			transition: color, background-color 0.2 ease-in-out;
			&:hover {
				color: $dark-blue;
			}
		}
	}
	&__collapse-btn {
		position: relative;
		&:after {
			@include square($size: 24px);
			@include absolute(
				$right: 0,
				$top: 2px,
				$content: url("/public/images/icons/ui/navigation/blue/drop_next.svg")
			);
			transform: rotate(90deg);
		}
		&.open:after {
			transform: rotate(-90deg);
		}
	}
	&__parent-list {
		display: block !important;
		li {
			position: relative;
			border-radius: 8px;
			&.active {
				color: $dark-blue;
			}
			&:after {
				@include square($size: 24px);
				@include absolute(
					$right: 8px,
					$top: 50%,
					$translateY: -50%,
					$content: url("/public/images/icons/ui/navigation/gray/drop_next.svg")
				);
				display: none;
			}
			&:hover {
				background-color: $grey100;
				&:after {
					display: block;
				}
			}
		}
	}
	&__child-list {
		flex: 1;
		ul {
			&:not(&.h100) {
				height: max-content;
			}
			height: max-content;
			display: inline-flex;
			vertical-align: top;
			&:not(:last-child) {
				// margin-right: 32px;
			}
		}
	}
}

.category-input {
	padding-right: 40px !important;
}
