@mixin circle($size, $bgColor: false) {
	width: $size;
	height: $size;
	border-radius: 50%;
	@if $bgColor {
		background-color: $bgColor;
	}
}

@mixin square($size, $bgColor: false) {
	width: $size;
	height: $size;
	@if $bgColor {
		background-color: $bgColor;
	}
}

@mixin row($justify: center, $align: center) {
	display: flex;
	align-items: $align;
	justify-content: $justify;
}

@mixin col {
	display: flex;
	flex-direction: column;
}

@mixin col-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin row-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin row-between-middle {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin row-start-middle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@mixin row-end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@mixin H3 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

@mixin H5 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

@mixin hover-svg {
	path {
		transition: fill 0.3s ease-in-out;
	}
	&:hover,
	&:hover path {
		fill: #005bff;
	}
}

@mixin absolute(
	$position: absolute,
	$content: "",
	$height: false,
	$width: false,
	$translateY: false,
	$translateX: false,
	$top: false,
	$bottom: false,
	$left: false,
	$right: false,
	$zIndex: false,
	$bgColor: false
) {
	position: $position;
	content: $content;
	@if $top {
		top: $top;
	}
	@if $bottom {
		bottom: $bottom;
	}
	@if $left {
		left: $left;
	}
	@if $right {
		right: $right;
	}
	@if $width {
		width: $width;
	}
	@if $height {
		height: $height;
	}
	@if $translateY and $translateX==false {
		transform: translate(0, $translateY);
	}
	@if $translateX and $translateY==false {
		transform: translate($translateX, 0);
	}
	@if $translateX and $translateY {
		transform: translate($translateX, $translateY);
	}
	@if $zIndex {
		z-index: $zIndex;
	}
	@if $bgColor {
		background-color: $bgColor;
	}
}
