.tooltip {
	position: absolute;
	transform: translate(8px, -100%);
	z-index: 2;
	display: none;
	width: max-content;
	border-radius: 8px;
	box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
	right: -100%;
	top: -10px;
	pointer-events: none;
	&--center {
		right: unset;
		left: 50%;
		transform: translate(-50%, -100%);
	}
	&-wrap {
		position: relative;
		&:hover .tooltip {
			display: block;
		}
	}
	&-text {
		max-width: 278px;
		width: 100%;
		position: relative;
		color: #212121;
		&:after {
			position: absolute;
			transform: translate(-50%, 100%);
			bottom: 0;
			left: 50%;
			border-width: 5.5px 7px;
			border-style: solid;
			border-color: #ffffff transparent transparent transparent;
		}
	}
	&-arrow {
		position: absolute;
		transform: translate(-50%, 100%);
		bottom: 0;
		right: 24px;
		border-width: 5.5px 7px;
		border-style: solid;
		border-color: #ffffff transparent transparent transparent;
		&--center {
			transform: translate(50%, 100%);
			right: 50%;
		}
	}
}
