.avatar {
	background: url("/public/images/icons/ui/actions/gray/person-big.svg")
		no-repeat center #f4f4f8;
	background-color: $grey100;
	overflow: hidden;
	img {
		@include square($size: 100%);
		object-fit: cover;
	}
	&-circular {
		border-radius: 50%;
	}
	&-120 {
		@include square($size: 120px);
		svg {
			@include square($size: 64px);
		}
	}
	&-48 {
		@include square($size: 48px);
		svg {
			@include square($size: 24px);
		}
	}
}
