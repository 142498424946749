$base-indent: 2;
$base-negative-indent: -2;
@mixin createIdent(
	$base-indent,
	$exponent,
	$property,
	$base-classname,
	$screen
) {
	@for $i from 1 through $exponent {
		.#{$base-classname}t-#{$screen}#{$base-indent * $i} {
			#{$property}-top: $base-indent * $i + px;
		}
		.#{$base-classname}r-#{$screen}#{$base-indent * $i} {
			#{$property}-right: $base-indent * $i + px;
		}
		.#{$base-classname}b-#{$screen}#{$base-indent * $i} {
			#{$property}-bottom: $base-indent * $i + px;
		}
		.#{$base-classname}l-#{$screen}#{$base-indent * $i} {
			#{$property}-left: $base-indent * $i + px;
		}
		.#{$base-classname}-#{$screen}#{$base-indent * $i} {
			#{$property}: $base-indent * $i + px;
		}
		.#{$base-classname}h-#{$screen}#{$base-indent * $i} {
			#{$property}-left: $base-indent * $i + px;
			#{$property}-right: $base-indent * $i + px;
		}
		.#{$base-classname}v-#{$screen}#{$base-indent * $i} {
			#{$property}-top: $base-indent * $i + px;
			#{$property}-bottom: $base-indent * $i + px;
		}
	}
	.#{$base-classname}-#{$screen}0 {
		#{$property}: 0;
	}
	.#{$base-classname}t-#{$screen}0 {
		#{$property}-top: 0;
	}
	.#{$base-classname}r-#{$screen}0 {
		#{$property}-right: 0;
	}
	.#{$base-classname}b-#{$screen}0 {
		#{$property}-bottom: 0;
	}
	.#{$base-classname}l-#{$screen}0 {
		#{$property}-left: 0;
	}
}

@mixin createGapIdent(
	$base-indent,
	$exponent,
	$property,
	$base-classname,
	$screen
) {
	@for $i from 1 through $exponent {
		.#{$base-classname}-#{$screen}#{$base-indent * $i} {
			#{$property}: $base-indent * $i + px;
		}
	}
	.#{$base-classname}-#{$screen}0 {
		#{$property}: 0;
	}
}

@include createIdent($base-indent, 150, "padding", "p", "");
@include createIdent($base-indent, 70, "margin", "m", "");
@include createGapIdent($base-indent, 70, "row-gap", "rGap", "");
@include createGapIdent($base-indent, 70, "column-gap", "cGap", "");
.rGap-1 {
	row-gap: 1px;
}
