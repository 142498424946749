#modal-root {
	.main-modal {
		.brightbox-info-modal {
			background: #fff;
			border-radius: 16px;
			box-sizing: border-box;
			display: block;
			padding: 32px;
			position: relative;
			width: 461px;
			padding-bottom: 0px;
			.modal-field-title {
				margin-bottom: 24px;
				font-size: 24px;
				font-weight: 600;
				line-height: 40px;
				position: relative;
				b {
					display: block;
					position: relative;
					margin-bottom: -9px;
				}
				small {
					position: relative;
					display: inline-block;
					font-weight: 400;
					font-size: 13px;
					color: #94a0b1;
					line-height: 28px;
					&.brightbox-info-status {
						color: #212121;
						padding: 0 12px;
						background: #f4f4f8;
						border-radius: 80px;
						margin-right: 8px;
						&.is-click {
							color: #fff;
							background: #0dc268;
						}
					}
				}
			}
			.modal-field-close {
				cursor: pointer;
				display: block;
				position: absolute;
				right: 40px;
				top: 40px;
			}
			.brightbox-info-modal-content {
				position: relative;
				display: flex;
				justify-content: space-between;
				gap: 24px;
				padding-bottom: 24px;
				text-decoration: none;
				color: #212121;
				//margin-bottom: 24px;
				//border-bottom: 1px solid #E2E8F0;
				img {
					position: relative;
					display: block;
					width: 80px;
					height: 80px;
					object-fit: cover;
					border-radius: 8px;
				}
				.brightbox-info-modal-content-data {
					position: relative;
					width: calc(100% - 80px);
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					span {
						position: relative;
						display: block;
						font-weight: 400;
						font-size: 13px;
						line-height: 18px;
						color: #94a0b1;
						margin-bottom: 6px;
					}
					b {
						position: relative;
						display: block;
						margin-top: 6px;
						color: #212121;
						font-weight: bold;
					}
				}
			}
			.brightbox-info-modal-info {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 12px;
				padding: 16px 32px 24px;
				margin: 0 -32px;
				border-top: 1px solid #e2e8f0;
				border-bottom: 1px solid #e2e8f0;
				span {
					position: relative;
					display: block;
					font-weight: 400;
					font-size: 13px;
					line-height: 18px;
					color: #94a0b1;
					margin-bottom: 2px;
				}
				p {
					position: relative;
					display: block;
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					text-decoration: none;
					color: #212121;
				}
			}
			.brightbox-info-modal-buttons {
				position: relative;
				display: flex;
				justify-content: center;
				padding: 16px 32px;
				margin: 0 -32px;
				background: #f4f4f8;
				border-radius: 0 0 16px 16px;
				a {
					position: relative;
					display: block;
					font-size: 15px;
					line-height: 24px;
					color: #212121;
					text-decoration: none;
					&:hover {
						color: #005bff;
						path {
							fill: #005bff;
						}
					}
					svg {
						position: relative;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}
	}
}
