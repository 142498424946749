@import "../../styles/base/index";
.fields-container {
	display: flex;
	flex-wrap: wrap;
	column-gap: 16px;
}

// Label.tsx
.label {
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 22px;
	color: #94a0b1;
	width: 100%;
	display: flex;
	justify-content: space-between;

	&__link,
	a {
		font-size: 13px;
		line-height: 21px;
	}
	span {
		position: relative;
		display: block;
		font-weight: 600;
		font-size: 15px;
		color: #212121;
	}
}
.filter-label {
	margin-top: -3px;
}
// Field.tsx
.field {
	position: relative;
	width: 100%;
	display: inline-flex;
	margin-bottom: 24px;
	&-wrap {
		width: 100%;
	}
	&--fourth {
		width: 25%;
	}
	&--third {
		width: 38.065%;
	}
	&--half {
		width: calc(50% - 16px / 2);
	}
	&__bottom-link {
		color: #005bff;
		font-size: 13px;
		line-height: 21px;
		font-weight: 600;
	}
}

// Input.tsx
.input-field {
	position: relative;
	display: block;
	width: 100%;
	&.show-password {
		.input-field-eye {
			background: url("/public/images/icons/ui/forms/eye_off.svg");
			background-size: contain;
			opacity: 1;
		}
	}
	.input-icon {
		position: absolute;
		display: block;
		width: 20px;
		height: 20px;
		top: 18px;
		right: 16px;
		pointer-events: none;
	}
	.input-field-eye {
		background-image: url("/public/images/icons/ui/forms/eye_off.svg");
		background-size: contain;
		cursor: pointer;
		pointer-events: all;
		opacity: 0.4;
		&--open {
			background-image: url("/public/images/icons/ui/forms/eye.svg");
		}
	}
	.input-search {
		background: url("/public/images/icons/ui/navigation/search.svg");
		background-size: contain;
	}
	&.left-icon {
		.input-icon {
			right: auto;
			left: 16px;
		}
		input {
			padding-left: 50px;
		}
	}
	//&.date-range-field{
	//  position: relative;
	//  display: flex;
	//  justify-content: flex-start;
	//  background: #F4F4F8;
	//  border-radius: 8px;
	//  font-size: 15px;
	//  height: 54px;
	//  input{
	//    &#statistics-range1{
	//      position: relative;
	//      display: inline-block;
	//      vertical-align: middle;
	//      width: 70px;
	//      padding: 0;
	//      padding-left: 20px;
	//      &:hover{
	//        border: 0;
	//      }
	//      &:focus{
	//        border: 0;
	//      }
	//    }
	//    &#statistics-range2{
	//      position: relative;
	//      display: inline-block;
	//      vertical-align: middle;
	//      padding: 0;
	//      &:hover{
	//        border: 0;
	//      }
	//      &:focus{
	//        border: 0;
	//      }
	//    }
	//  }
	//}
	#statistics-range-calendar {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: url("/public/images/icons/ui/forms/calendar.svg") no-repeat calc(100% - 16px) 15px;
	}
	#statistics-range-calendar-clear {
		cursor: pointer;
		display: none;
		position: absolute;
		right: 45px;
		top: 16px;
		line-height: 0;
		&:hover {
			svg {
				path {
					fill: #005bff;
				}
			}
		}
	}
	input,
	.input {
		position: relative;
		display: block;
		outline: none;
		background: #f4f4f8;
		border-radius: 8px;
		font-size: 15px;
		height: 54px;
		border: 1px solid #f4f4f8;
		font-family: "Averta CY";
		color: #212121;
		padding: 0 20px;
		box-sizing: border-box;
		width: 100%;
		&#statistics-range-visible {
			pointer-events: none;
		}
		&:not(&--error):not(:active):hover {
			border: 1px solid #d1d8e1;
		}
		&:focus,
		&:focus:hover,
		&:active,
		&:active:hover {
			border: 1px solid #005bff;
			&::placeholder {
				color: rgba(0, 0, 0, 0);
			}
		}
		&:disabled,
		&-disabled {
			background: #d1d8e1;
			border: 1px solid #d1d8e1;
			color: #94a0b1;
		}
		&::placeholder {
			color: #94a0b1;
		}
		&--error {
			border-color: #ed0a34;
		}
	}
	input[type="password"] {
		padding-right: 40px;
	}
	textarea {
		position: relative;
		display: block;
		outline: none;
		background: #f4f4f8;
		border-radius: 8px;
		font-size: 15px;
		line-height: 24px;
		min-height: 54px;
		height: auto;
		background: #f4f4f8;
		border: 1px solid #f4f4f8;
		font-family: "Averta CY";
		resize: none;
		color: #212121;
		padding: 15px 20px;
		box-sizing: border-box;
		width: 100%;
		&:hover {
			border: 1px solid #d1d8e1;
		}
		&:focus {
			border: 1px solid #005bff;
			&::placeholder {
				color: rgba(0, 0, 0, 0);
			}
		}
		&:disabled {
			background: #d1d8e1;
			border: 1px solid #d1d8e1;
			color: #94a0b1;
		}
		&::placeholder {
			color: #94a0b1;
		}
	}
	&.disabled {
		pointer-events: none;
		input {
			color: #94a0b1;
			background: #d1d8e1;
		}
		.select-block {
			color: #94a0b1;
			background: #d1d8e1;
		}
	}
	.select-block {
		position: relative;
		display: block;
		outline: none;
		background: #f4f4f8;
		border-radius: 8px;
		font-size: 15px;
		height: 54px;
		background: #f4f4f8;
		border: 1px solid #f4f4f8;
		font-family: "Averta CY";
		cursor: pointer;
		&.in-statistics {
			background: none;
			border: 1px solid #fff;
			height: 24px;
			&:hover {
				border: 1px solid #fff;
				.select-block-button {
					span {
						color: #005bff;
					}
					svg {
						path {
							fill: #005bff;
						}
					}
				}
			}
			&.active {
				.select-block-button {
					svg {
						transform: rotate(180deg);
					}
				}
			}
			.select-block-button {
				line-height: 24px;
				padding: 0;
				svg {
					position: absolute;
					display: block;
					content: "";
					width: 24px;
					height: 24px;
					right: 0;
					top: 0;
					pointer-events: none;
				}
				&:after {
					display: none;
				}
				span {
					position: absolute;
					display: block;
					width: calc(100% - 28px);
					white-space: nowrap;
					overflow: hidden;
					font-weight: 600;
					text-overflow: ellipsis;
				}
			}
		}
		&:hover {
			border: 1px solid #d1d8e1;
		}
		&.active {
			.select-block-button {
				&:after {
					transform: rotate(180deg);
				}
			}
			.select-block-list {
				display: block;
			}
		}
		.select-block-control {
			display: none;
		}
		&.multiselect {
			.select-block-list {
				.select-block-list-scroll {
					max-height: 246px;
					.select-block-item {
						span {
							padding-left: 38px;
							width: calc(100% - 100px);
						}
						&:before {
							position: absolute;
							display: block;
							left: 20px;
							top: calc(50% - 12px);
							content: "";
							width: 24px;
							height: 24px;
							box-sizing: border-box;
							background: #f4f4f8;
							border: 1px solid #d1d8e1;
							border-radius: 4px;
						}
						&.selected {
							&:before {
								background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50%
									50%;
								border-color: #005bff;
							}
							&:hover {
								span {
									color: #212121;
								}
							}
						}
						&:hover {
							span {
								color: #005bff;
							}
						}
					}
				}
				.select-block-list-bar {
					height: calc(100% - 76px);
				}
				.select-block-control {
					position: relative;
					display: flex;
					justify-content: space-between;
					height: 44px;
					border-top: 1px solid #e2e8f0;
					.select-block-cancel {
						position: relative;
						line-height: 44px;
						padding: 0 20px;
						font-size: 15px;
						color: #94a0b1;
						cursor: pointer;
					}
					.select-block-select {
						position: relative;
						line-height: 44px;
						padding: 0 20px;
						font-size: 15px;
						color: #005bff;
						cursor: pointer;
					}
				}
			}
		}
		.select-block-button {
			font-size: 15px;
			line-height: 54px;
			padding: 0 20px;
			&.not-selected {
				color: #94a0b1;
			}
			&:after {
				position: absolute;
				display: block;
				content: "";
				width: 24px;
				height: 24px;
				top: 16px;
				right: 16px;
				pointer-events: none;
				background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
			}
			span {
				position: absolute;
				display: block;
				width: calc(100% - 60px);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.select-block-list {
			position: absolute;
			display: none;
			cursor: default;
			width: 100%;
			top: calc(100% + 8px);
			z-index: 100;
			background: #ffffff;
			box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
			border-radius: 8px;
			overflow-x: hidden;
			-block-list-bar {
				position: absolute;
				display: block;
				width: 4px;
				pointer-events: none;
				top: 16px;
				right: 8px;
				height: calc(100% - 32px);
				border-radius: 2px;
				overflow: hidden;
				.select-block-list-thumb {
					position: absolute;
					height: 30%;
					width: 100%;
					left: 0;
					top: 0;
					background: #94a0b1;
					opacity: 0.3;
					border-radius: 2px;
				}
			}
			.select-block-list-scroll {
				position: relative;
				display: block;
				width: calc(100% + 20px);
				overflow-y: scroll;
				max-height: 280px;
				.select-block-item {
					position: relative;
					display: block;
					height: 40px;
					font-size: 15px;
					line-height: 40px;
					padding: 0 20px;
					cursor: pointer;
					&:first-child {
						margin-top: 16px;
					}
					&:last-child {
						margin-bottom: 16px;
					}
					&:hover {
						background: #f4f4f8;
						span {
							color: #005bff;
						}
					}
					span {
						position: absolute;
						display: block;
						width: calc(100% - 60px);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}

// Error.tsx
.field-error {
	color: #ed0a34;
	font-size: 13px;
	line-height: 16px;
	// margin: 8px 0;
	width: 100%;
	margin-top: -8px;
	margin-bottom: 8px;
	padding-bottom: 8px;
	// position: absolute;
	// top: -16px;
	// transform: translateY(-100%);
	// left: 0;
}

// Error.tsx
.checkbox {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	position: relative;
	&:checked {
		background-color: #005bff;
		border-color: #005bff;
		&:after {
			position: absolute;
			content: url("/public/images/icons/ui/navigation/white/check-mark.svg");
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&:not(:checked) {
		background-color: #f4f4f8;
		border: 1px solid #d1d8e1;
	}
	&:disabled {
		background-color: #d1d8e1;
		color: #d1d8e1;
	}
	&-wrap {
		cursor: pointer;
		display: flex;
		align-items: center;
		color: #94a0b1;
		transition:
			color,
			border-color,
			background-color 0.4s ease-in-out;
		&:hover {
			color: #005bff;
		}
		label a:hover {
			border-color: #005bff;
			color: #005bff;
		}
	}
	&-label {
		// margin-left: 12px;
		a {
			border-bottom: 1px solid #e2e8f0;
			display: inline-block;
		}
	}
	&--error {
		border-color: #ed0a34;
	}
}

.select {
	cursor: pointer;
	font-size: 15px;
	font-family: "Averta CY";
	@include row-between;
	column-gap: 4px;
	button {
		color: inherit;
		&.expanded::after {
		}
	}
	&-search {
		color: #212121;
		padding: 0 !important;
		border: none !important;
		&:hover {
			border: none !important;
		}
		&:placeholder {
			color: #94a0b1;
		}
	}
	&-options-wrapper {
		display: none;
		&.open {
			display: block;
		}
	}
	&-options {
		position: absolute;
		z-index: 4;
		top: calc(100% + 8px);
		left: 0;
		width: 100%;
		display: none;
		list-style: none;
		box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
		border-radius: 8px;
		background-color: #fff;
		padding: 4px 0;
		// overflow-y: auto;
		overflow: hidden;
		max-height: 300px;
		// &::-webkit-scrollbar {
		//     width: 10px;
		//     background-color: #f9f9fd;
		// }
		// &::-webkit-scrollbar-thumb {
		//     background-color: #d1d8e1;
		// }
		&__inner {
			max-height: 268px;
			overflow-y: scroll;
			width: calc(100% - 8px);
		}
		&.show {
			display: block;
		}
		&__item {
			padding: 12px 20px;
			cursor: pointer;
			position: relative;
			&:active,
			&:focus,
			&:hover {
				background: #f4f4f8;
				color: #005bff;
			}
			&--checkbox {
				padding-left: 56px;
				span {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				&:before {
					background: #f4f4f8;
					border: 1px solid #d1d8e1;
					border-radius: 4px;
					box-sizing: border-box;
					content: "";
					display: block;
					height: 24px;
					left: 20px;
					position: absolute;
					top: calc(50% - 12px);
					width: 24px;
				}
				&.selected:before {
					background: #005bff url("/public/images/icons/ui/navigation/white/check-mark.svg") no-repeat 50% 50%;
					border-color: #005bff;
				}
			}
		}
	}
	.chosen-multiple {
		position: absolute !important;
		width: calc(100% - 60px) !important;
	}
	&__chosen-options {
		// flex: 1;
		// width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 4px;
		flex-wrap: wrap;
		max-width: 100%;
	}
	&__chosen-tag {
		background: #d1d8e1;
		border-radius: 6px;
		padding: 4px 8px;
		display: flex;
		align-items: center;
		&_remove-btn {
			width: 16px;
			height: 16px;
			background: url("/public/images/icons/ui/navigation/close_small.svg") no-repeat;
			background-size: 16px 16px;
			margin-left: 4px;
		}
	}
	&__remove-all-btn {
		position: absolute;
		right: calc(16px + 4px + 24px);
		top: 16px;
	}
	&-options__fixed-bottom {
		position: sticky;
		bottom: 0;
		background: #fff;
		padding: 12px 16px;
		&:before {
			position: absolute;
			height: 1px;
			width: 100%;
			background-color: #e2e8f0;
			content: "";
			top: 0;
			left: 0;
		}
	}
}

.email-veirify-hint {
	background-color: #fd4aa7;
	padding: 12px 20px;
	border-radius: 8px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
	font-size: 14px;
	line-height: 22px;
	&__left {
		display: flex;
		align-items: center;
		column-gap: 16px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		p {
			flex: 1;
		}
	}
	.btn {
		white-space: nowrap;
		width: 225px;
		background-color: #fff;
	}
	@media screen and (max-width: 1024px) {
		padding-left: 16px;
		margin-top: 8px;
		&__left {
			align-items: flex-start;
			font-size: 13px;
			line-height: 18px;
			column-gap: 14px;
			height: 40px;
			font-size: 14px;
			line-height: 22px;
			p {
				max-width: 120px;
			}
		}
	}
}
