.feedback-popup {
	form {
		flex-wrap: wrap;
	}
	&__file-field {
		.label {
			color: #94a0b1;
		}
		&_drop-zone {
			background: #f4f4f8;
			border-radius: 8px;
			display: block;
		}
		span {
			color: #94a0b1;
			font-weight: 400;
			font-size: 13px;
			line-height: 21px;
		}
	}
	textarea {
		height: 104px !important;
		padding: 16px 20px !important;
	}
	.field {
		margin-bottom: 0 !important;
	}
}
