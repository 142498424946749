.product-page {
	.form-page-title {
		max-width: unset !important;
	}
	.category-input {
		background: #f4f4f8 !important;
		border: 1px solid #f4f4f8 !important;
		color: #212121 !important;
		cursor: pointer;
		caret-color: transparent;
	}
	&__img {
		@include square($size: 80px);
		border-radius: 12px;
		margin-right: 24px;
		background-color: #f4f4f8;
		overflow: hidden;
		&:empty {
			background-color: #e2e8f0;
		}
		img {
			object-fit: cover;
			@include square($size: 100%);
			background-color: #e2e8f0;
		}
	}
	&__add-status {
		width: max-content;
		background: #ffffff;
		border-radius: 16px;
		padding: 32px;
		@include row-between-middle();
		column-gap: 16px;
		width: 100%;
	}
	.tooltip-text {
		max-width: 220px;
	}
}
