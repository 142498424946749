.personal-area-header {
	display: block;
	// height: 72px;
	// margin-bottom: 32px;
	width: 100%;
	// position: absolute;
	background: transparent;
	z-index: 10;
	&__balance {
		padding: 5px 12px;
		background: #7027c2;
		border-radius: 80px;
		margin-right: 16px;
	}
	.blogger-chat-btn {
		svg path {
			fill: #212121;
		}
	}
	.personal-area-header-wrapper {
		// padding: 0 40px 0;
		height: 72px;
		display: block;
		position: relative;
		width: 100%;
		font-size: 14px;
		line-height: 160%;
		color: #fff;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	button.button_aside-toggler {
		cursor: pointer;
		display: block;
		left: 0px;
		position: absolute;
		top: 32px;
		width: 24px;
		background: transparent;
		border: none;
		img {
			width: 24px;
			height: 24px;
		}
	}
	.user-profile-icon {
		width: 40px;
		height: 40px;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		// position: absolute;
		right: 0;
		top: 24px;
		background-color: #fff;
		span {
			cursor: default;
			width: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 40px;
			font-size: 16px;
			color: white;
			max-height: 40px;
			text-transform: uppercase;
			&:hover {
				color: white;
			}
		}
	}
}
