.section-pagination {
    display: block;
    width: 100%;
    margin: 64px auto 0;
    display: flex;
    justify-content: center;
    .pages-counts {
        .pages-list {
            list-style-type: none;
            display: flex;
            height: 56px;
            align-items: center;
            display: flex;
            gap: 4px;
            justify-content: center;
            position: relative;
            &__item {
                align-items: center;
                border-radius: 4px;
                color: #212121;
                cursor: pointer;
                display: flex;
                height: 40px;
                justify-content: center;
                position: relative;
                font-weight: 400;
                font-size: 14px;
                width: 40px;
                &.current {
                    background: #005bff;
                    color: #fff;
                    pointer-events: none;
                }
                &.button {
                    width: 56px;
                    height: 56px;
                    button {
                        align-items: center;
                        background: #f4f4f8;
                        border-radius: 80px;
                        cursor: pointer;
                        display: flex;
                        height: 56px;
                        justify-content: center;
                        position: relative;
                        width: 56px;
                        border: none;
                        outline: none;
                        &:disabled {
                            background: none;
                            opacity: 0.3;
                            pointer-events: none;
                        }
                    }
                }
                &.button_right {
                    margin-left: 20px;
                    svg {
                        margin: 0;
                    }
                }
                &.button_left {
                    margin-right: 20px;
                    svg {
                        margin: 0;
                    }
                }
            }
        }
    }
}